import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, Box, useMediaQuery, useTheme } from "@mui/material";
import Image from "../common/Image";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import {
  useGetGameCategoryQuery,
  useGetGameProviderQuery,
} from "../../features/api/gameApiSlice";
import * as Constant from "../../features/constant";

const certificationIcon = [
  process.env.REACT_APP_FOOTER + "/certificate1.png",
  process.env.REACT_APP_FOOTER + "/certificate2.png",
  process.env.REACT_APP_FOOTER + "/certificate3.png",
  process.env.REACT_APP_FOOTER + "/certificate4.png",
  process.env.REACT_APP_FOOTER + "/certificate5.png",
];

const paymentIcon = [
  process.env.REACT_APP_FOOTER + "/payment1.png",
  process.env.REACT_APP_FOOTER + "/payment2.png",
  process.env.REACT_APP_FOOTER + "/payment3.png",
  process.env.REACT_APP_FOOTER + "/payment4.png",
  process.env.REACT_APP_FOOTER + "/payment5.png",
  process.env.REACT_APP_FOOTER + "/payment6.png",
  process.env.REACT_APP_FOOTER + "/payment7.png",
];

const securityIcon = [process.env.REACT_APP_FOOTER + "/security1.png"];

const responsibleGamingIcon = [
  process.env.REACT_APP_FOOTER + "/responsible1.png",
  process.env.REACT_APP_FOOTER + "/responsible2.png",
  process.env.REACT_APP_FOOTER + "/responsible3.png",
];

const FooterAffiliate = ({ background, contactDetails }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletView = useMediaQuery(theme.breakpoints.down("lg"));

  // const {
  //   data: gameCategory,
  //   isLoading: isGameCategoryLoading,
  //   isSuccess: isGameCategorySuccess,
  // } = useGetGameCategoryQuery();

  // const {
  //   data: gameProvider,
  //   isLoading: isGameProviderLoading,
  //   isSuccess: isGameProviderSuccess,
  // } = useGetGameProviderQuery({ category: gameCategory?.result[0]?.code });

  const links = [
    i18n.t("footer.home"),
    i18n.t("footer.aboutus"),
    // i18n.t("footer.game"),
    i18n.t("footer.bettingrules"),
    i18n.t("footer.t&c"),
    // i18n.t("footer.promotion"),
    i18n.t("footer.faq"),
  ];

  const links3 = [
    i18n.t("footer.responsiblegaming"),
    i18n.t("footer.kycverification"),
    i18n.t("footer.antiscam"),
  ];

  return (
    <Box
      sx={{
        background: `url('${background}')`,
      }}
    >
      {/* <Grid
        container
        // columns={4}
        direction={mobileView ? "column" : "row"}
        wrap="nowrap"
        p={0}
        px={{ sm: "100px", md: "300px" }}
        sx={{
          backgroundColor:"#d0aa1c" ,
        }}
      >
        {links.map((item, index) => {
          return (
            <Grid
              item
              xs
              p={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                sx={{
                  color: "white",
                  textTransform: "UPPERCASE",
                  fontSize: 14,
                  cursor: "pointer",
                  textAlign: "center",
                }}
                onClick={() => {
                  if (item === t("footer.home")) {
                    navigate(Constant.getPath("/"));
                  } else if (item === t("footer.game")) {
                    navigate(
                      Constant.getPath(`/game/${gameCategory?.result[0].code?.toLowerCase().replaceAll("_", "-")}`),
                      {
                        state: {
                          providerId: gameProvider?.result[0]?.id,
                          bannerUrl: gameCategory?.result[0].bannerUrl,
                        },
                      }
                    );
                  } else if (item === t("footer.promotion")) {
                    navigate(Constant.getPath("/promotion"));
                  } else if (item === t("footer.aboutus")) {
                    navigate(Constant.getPath("/about"));
                  } else if (item === t("footer.bettingrules")) {
                    navigate(Constant.getPath("/rules"));
                  } else if (item === t("footer.t&c")) {
                    navigate(Constant.getPath("/terms"));
                  } else if (item === t("footer.faq")) {
                    navigate(Constant.getPath("/faq"));
                  }
                  window.scroll(0, 0);
                }}
              >
                {item}
              </Typography>
            </Grid>
          );
        })}
      </Grid> */}
      <Grid
        container
        columns={2}
        direction={mobileView ? "column" : "row"}
        wrap="nowrap"
        p={1}
        px={tabletView ? 10 : 20}
      >
          {/* Official Partner */}
          <Grid container spacing={2}>
              <Grid item xs={12}>
                  <Grid container spacing={2}>
                  <Grid item lg={1.7} />
                      <Grid item xs={12} sm={6} md={3} lg={2} >
              <Box
                  sx={{ display: "flex", flexDirection: "column", maxWidth: "300px"}}
              >
                  <Typography
                      variant="h6"
                      sx={{
                          mb: 2,
                          color: "white",
                          textTransform: "uppercase",
                          fontSize: "1em",
                          fontWeight: "600",
                      }}
                  >
                      {t("footer.partnertitle")}
                  </Typography>
                  <img src={process.env.REACT_APP_ICON_PARTNER}   style={{ width: '100px', height: '100px', textAlign: 'center' }}
                  />
                  <Typography
                      sx={{
                          mt: 2,
                          color: "white",
                          fontSize: 14,
                      }}
                  >
                      {t("footer.partnername")}
                  </Typography>
              </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={5.5}>
              <Box
                  sx={{ display: "flex", flexDirection: "column"}}
              >
                  <Typography
                      variant="h6"
                      sx={{
                          //mb: 2,
                          color: "white",
                          textTransform: "uppercase",
                          fontSize: "1em",
                          fontWeight: "600",
                      }}
                  >
                      {t("footer.ambassador")}
                  </Typography>
                  <Image src={process.env.REACT_APP_INFLUENCER_FOOTER}  maxWidth= "100%" height = "auto" style={{ objectFit: "contain"}}/>
                  <Typography
                      sx={{
                          mt: 2,
                          color: "white",
                          fontSize: 14,
                      }}
                  >
                  </Typography>
              </Box>
         
          </Grid>
               {/* Security */}
        <Grid item xs={12} sm={6} md={2} lg={1.5}>
          <Box
            sx={{ display: "flex", flexDirection: "column", maxWidth: "400px" }}
          >
            <Typography
              variant="h6"
              sx={{
                color: "white",
                textTransform: "uppercase",
                fontSize: "1em",
                fontWeight: "600",
              }}
            >
              {t("footer.security")}
            </Typography>
            <Grid container py={2} spacing={2}>
              {securityIcon.map((icon, index) => (
                <Grid item key={index} display="flex">
                  <Image src={icon}  alt={Constant.ALT_TAG_FOOTER_SECURITY[index]}/>
                </Grid>
              ))}
            </Grid>
            <Typography
              variant="h6"
              sx={{
                color: "white",
                textTransform: "uppercase",
                fontSize: "1em",
                fontWeight: "600",
              }}
            >
              {t("footer.responsiblegaming")}
            </Typography>
            <Grid container py={2} spacing={2}>
              {responsibleGamingIcon.map((icon, index) => (
                <Grid item key={index} display="flex">
                  <Image src={icon}  alt={Constant.ALT_TAG_FOOTER_RESP_GAMING[index]}/>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
              </Grid>
          </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        // justifyContent="space-between"
        py={2}
        px={tabletView ? 10 : 20}
      >
        {/* Gaming License */}
        <Grid item lg={1.7} />
        <Grid item xs={12} sm={6} md={3} lg={2} >
          <Box
            sx={{ display: "flex", flexDirection: "column", maxWidth: "300px" }}
          >
            <Typography
              variant="h6"
              sx={{
                mb: 2,
                color: "white",
                textTransform: "uppercase",
                fontSize: "1em",
                fontWeight: "600",
              }}
            >
              {t("footer.gaminglicense")}
            </Typography>
            <Image src={process.env.REACT_APP_FOOTER + "/certificate6.png"} alt={Constant.ALT_TAG_FOOTER_LICENSE}/>
            <Typography
              sx={{
                mt: 2,
                color: "white",
                fontSize: 12,
              }}
            >
              {t("footer.gaminglicensedesc")}
            </Typography>
          </Box>
        </Grid>
        {/* Certification */}
        <Grid item lg={5} >
          <Box sx={{ display: "flex", flexDirection: "column"}}>
            <Typography
              variant="h6"
              sx={{
                color: "white",
                textTransform: "uppercase",
                fontSize: "1em",
                fontWeight: "600",
               }}
            >
              {t("footer.certification")}
            </Typography>
            <Grid container py={2} spacing={2} >
              {certificationIcon.map((icon, index) => (
                <Grid item key={index} display="flex" >
                  <Image src={icon} alt={Constant.ALT_TAG_FOOTER_CERT[index]} />
                </Grid>
              ))}
            </Grid>
            {/* <Typography
              variant="h6"
              sx={{
                color: "white",
                textTransform: "uppercase",
                fontSize: "1em",
                fontWeight: "600",
              }}
            >
              {t("footer.paymentmethod")}
            </Typography>
            <Grid container py={2} spacing={2}>
              {paymentIcon.map((icon, index) => (
                <Grid item key={index} display="flex">
                  <Image src={icon} alt={Constant.ALT_TAG_FOOTER_PAYMENT[index]} />
                </Grid>
              ))}
            </Grid> */}
          </Box>
        </Grid>
        {/* links */}
        {/* <Grid item xs={12} sm={6} md={3} lg={3}>
          <Grid container spacing={1}> */}
            {/* <Grid item>
              <Typography
                variant="h6"
                sx={{
                  color: "white",
                  textTransform: "UPPERCASE",
                  fontSize: "1em",
                  fontWeight: "600",
                }}
              >
                {t("footer.support")}
              </Typography>
              {links.map((item, index) => (
                <Box
                  disableRipple
                  sx={{
                    color: "#727272 !important",
                    "&.MuiButtonBase-root:hover": {
                      backgroundColor: "transparent",
                      color: theme.palette.secondary.main,
                    },
                    fontSize: 12,
                    textTransform: "UPPERCASE",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (item === t("footer.home")) {
                      navigate("/");
                    } else if (item === t("footer.game")) {
                      navigate(
                        // `/game/${gameCategory?.result[0].code?.toLowerCase()}`,
                        {
                          state: {
                            // providerId: gameProvider?.result[0]?.id,
                            // bannerUrl: gameCategory?.result[0].bannerUrl,
                          },
                        }
                      );
                    } else if (item === t("footer.promotion")) {
                      navigate(Constant.getPath("/promotion"));
                    } else if (item === t("footer.aboutus")) {
                      navigate(Constant.getPath("/about"));
                    } else if (item === t("footer.bettingrules")) {
                      navigate(Constant.getPath("/rules"));
                    } else if (item === t("footer.t&c")) {
                      navigate(Constant.getPath("/terms"));
                    } else if (item === t("footer.faq")) {
                      navigate(Constant.getPath("/faq"));
                    }
                    window.scroll(0, 0);
                  }}
                  underline="none"
                >
                  {item}
                </Box>
              ))} */}
            {/* </Grid>*/}
            {/* <Grid item> 
              <Typography
                variant="h6"
                sx={{
                  color: "white",
                  textTransform: "UPPERCASE",
                  fontSize: "1em",
                  fontWeight: "600",
                  marginTop: "10px"
                }}
              >
                {t("footer.responsiblegaming")}
              </Typography>
              {links3.map((item, index) => (
                <Box
                  disableRipple
                  sx={{
                    color: "#727272 !important",
                    "&.MuiButtonBase-root:hover": {
                      backgroundColor: "transparent",
                      color: theme.palette.secondary.main,
                    },
                    fontSize: 12,
                    textTransform: "UPPERCASE",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (item === t("footer.responsiblegaming")) {
                      navigate(Constant.getPath("/responsibleGaming"));
                    } else if (item === t("footer.kycverification")) {
                      navigate(Constant.getPath("/kycVerification"));
                    } else if (item === t("footer.antiscam")) {
                      navigate(Constant.getPath("/antiScam"));
                    }
                    window.scroll(0, 0);
                  }}
                  underline="none"
                >
                  {item}
                </Box>
              ))}
            </Grid> */}
          {/* </Grid>
        </Grid> */}
      </Grid>
    </Box>
  );
};

FooterAffiliate.propTypes = {
  background: PropTypes.string,
  contactDetails: PropTypes.object,
};

FooterAffiliate.defaultProps = {
  background: "",
  contactDetails: {},
};

export default FooterAffiliate;
