import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Box,
} from '@mui/material';
import { useTranslation } from "react-i18next";
import { useGetDownlineQuery, useGetDownlineFirstDepositDetailsQuery } from "../../features/api/userApiSlice";
import moment from 'moment';
import { useGetTeamSizeReportQuery, useGetAllDepositReportQuery, useGetAllWithdrawReportQuery, useGetAllDownlineTurnoverQuery, useGetMemberSummaryReportQuery, useGetMonthlyCommissionQuery } from "../../features/api/reportApiSlice";
import CustomPagination from './CustomPagination';
import interact from 'interactjs';
import AffiliateReportPopUp from './AffiliateReportPopUp';

const AffiliateReportTables = ({ menucode, startDate, endDate }) => {
  interact('.draggable-table-container').draggable({
    inertia: true,
    autoScroll: true,
    listeners: {
      move(event) {
        const scrollContainer = document.querySelector('.draggable-table-container');
        const deltaX = event.dx;
        scrollContainer.scrollLeft -= deltaX;
      }
    }
  });

  const [selectedData, setSelectedData] = useState(null);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false); // State to manage dialog open/close
  const [startdate, setStartdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const [buttonClicked,setButtonClicked] = useState("");
  const [isTeamSizeLoaded, setIsTeamSizeLoaded] = useState(false);
  const [isRowTeamSizeLoaded, setIsRowTeamSizeLoaded] = useState(false);

  const {
    data: teamSizeReport,
    isLoading: isteamSizeLoading,
    isSuccess: isteamSizeSuccess,
  } = useGetTeamSizeReportQuery({ prefetch: true });

  const [teamSize, setTeamSize] = useState({});

  useEffect(() => {
    if (!isteamSizeLoading && isteamSizeSuccess) {
      setTeamSize(teamSizeReport.data);
    }
  }, [isteamSizeLoading, isteamSizeSuccess, teamSizeReport]);

  //set page to 0 on changing menu
  useEffect(() => {
    setPage(0);
  }, [menucode]);

  useEffect(() => {
    setStartdate(startDate);
    setEnddate(endDate);
  }, [startDate, endDate]);

  const columnsTeamSizeDisplay = [
    t("teamsize.today"),
     t("teamsize.yesterday"),
      t("teamsize.thisweek"), 
      t("teamsize.lastweek"), 
      t("teamsize.thismonth")];
  const rowsTeamSizeDisplay = [
    t("teamsize.userregisters"),
    t("teamsize.userfirstdeposits"),
    t("teamsize.bettings"),
    t("teamsize.bettingvalidbets"),
    t("teamsize.bettingwinloss"),
    t("teamsize.deposittotalamount"),
    t("teamsize.deposittotalcount"),
    t("teamsize.withdrawaltotalamount"),
    t("teamsize.withdrawaltotalcount"),
    t("teamsize.totalreward")
  ]

  const columnsTeamSizeKey = ["Today", "Yesterday", "This Week", "Last Week", "This Month"];
  const rowsTeamSizeKey = ["User Registers","User First Deposits", "Bettings", "Betting Valid Bet",
    "Betting Win/Loss", "Deposit Total Amount", "Deposit Total Count", "Withdrawal Total Amount",
    "Withdrawal Total Count","Total Reward"]


  const clickableColumns = ['TotalCompanyWinLoss','Expenses','CalculatedCommission','Action','MoreDetails'];
  const clickableRows = ['User Registers','Betting Valid Bet','Deposit Total Count','Withdrawal Total Count','User First Deposits','Total Reward']

  useEffect(() => {

    if (columnsTeamSizeKey && columnsTeamSizeKey.length > 0) {
      setIsTeamSizeLoaded(true);
    }
  }, [columnsTeamSizeKey]);

  useEffect(() => {

    if (rowsTeamSizeKey && rowsTeamSizeKey.length > 0) {
      setIsRowTeamSizeLoaded(true);
    }
  }, [rowsTeamSizeKey]);

  const {
    data: downline,
    isSuccess: isGetDownlineSuccess,
    isError,
    error
  } = useGetDownlineQuery({
    startdate: startdate,
    enddate: enddate,
  });

  const {
    data: deposits,
    isSuccess: isGetDepositSuccess,
  } = useGetAllDepositReportQuery({
    startdate: startdate,
    enddate: enddate,
  });

  const {
    data: withdraws,
    isSuccess: isGetWithdrawSuccess,
  } = useGetAllWithdrawReportQuery({
    startdate: startdate,
    enddate: enddate,
  });

  const {
    data: firstDeposits,
    isSuccess: isGetFirstDepositDetailsSuccess,
  } = useGetDownlineFirstDepositDetailsQuery({
    startdate: startdate,
    enddate: enddate,
  });
  const {
    data: monthlyCommission,
    isSuccess: isGetMonthlyCommissionSuccess,
  } = useGetMonthlyCommissionQuery({
    startdate,
    enddate,
  });
  const {
    data: turnovers,
    isSuccess: isGetDownlineTurnoverSuccess,
  } = useGetAllDownlineTurnoverQuery({
    startdate: startdate,
    enddate: enddate,
  });

  const {
    data: memberreport,
    isSuccess: isGetMemberSummaryReportSuccess,
  } = useGetMemberSummaryReportQuery({
    startdate: startdate,
    enddate: enddate,
  });

  const handleRowClick = (column, rowData) => {

 //   console.log('Column:', column);
 //  console.log('Row Data:', rowData);

    if (clickableColumns.includes(column)) {

      // Add logic here to handle what happens when the row is clicked.
     // alert(`You clicked on ${column}: ${rowData[column]}`);
    //  console.log(column);
      setButtonClicked(column);
      setSelectedData(rowData); // Set the new table data
      setOpen(true); // Open the pop-up dialog

    }
  };

  const handleRowClick2 = (rowLabel,columnName) => {
  //  console.log(`${rowLabel} clicked and column: ${columnName}!`);

    setButtonClicked(rowLabel);
  //  console.log(buttonClicked);
  //  setSelectedData(rowData); // Set the new table data
    setOpen(true); // Open the pop-up dialog

  };

  const handleClose = () => {
    setOpen(false);
    setSelectedData(null); // Clear selected data when closed
  };

  const memberColumn = [
    { label: t("reportcolumns.member.id"), value: "Id" },
    { label: t("reportcolumns.member.login"), value: "Login" },
    { label: t("reportcolumns.member.registerdate"), value: "RegisterDate" },
    { label: t("reportcolumns.member.currency"), value: "Currency" },
    { label: t("reportcolumns.member.signupsite"), value: "SignUpSite" },
    { label: t("reportcolumns.member.accountstatus"), value: "AccountStatus" },
    { label: t("reportcolumns.member.firstdepositdate"), value: "FirstDepositDate" },
    { label: t("reportcolumns.member.action"), value: "Action" },
  ];

  const monthlyCommissionColumn = [
    { label: t("reportcolumns.monthlycommission.date"), value: "Date" },
    { label: t("reportcolumns.monthlycommission.currency"), value: "Currency" },
    { label: t("reportcolumns.monthlycommission.activemembers"), value: "ActiveMembers" },
    { label: t("reportcolumns.monthlycommission.totalentitled"), value: "TotalEntitled" },
    { label: t("reportcolumns.monthlycommission.winloss"), value: "WinLoss" },
    { label: t("reportcolumns.monthlycommission.sharedcost"), value: "SharedCost" },
    { label: t("reportcolumns.monthlycommission.rate"), value: "Rate" },
    { label: t("reportcolumns.monthlycommission.calculatedcommission"), value: "CalculatedCommission" },
    { label: t("reportcolumns.monthlycommission.adjustedamount"), value: "AdjustedAmount" },
    { label: t("reportcolumns.monthlycommission.commission"), value: "Commission" },
    { label: t("reportcolumns.monthlycommission.carryforward"), value: "CarryForward" },
    { label: t("reportcolumns.monthlycommission.actualrelease"), value: "ActualRelease" },
    { label: t("reportcolumns.monthlycommission.status"), value: "Status" },
  ];

  const memberSummaryColumn = [
    { label: t("reportcolumns.membersummary.currency"), value: "Currency" },
    { label: t("reportcolumns.membersummary.totalmemberregistered"), value: "TotalMemberRegistered" },
    { label: t("reportcolumns.membersummary.totalcompanywinloss"), value: "TotalCompanyWinLoss" },
    { label: t("reportcolumns.membersummary.totaldeposit"), value: "TotalDeposit" },
    { label: t("reportcolumns.membersummary.totalwithdrawal"), value: "TotalWithdrawal" },
    { label: t("reportcolumns.membersummary.validturnover"), value: "ValidTurnover" },
    { label: t("reportcolumns.membersummary.expenses"), value: "Expenses" },
    { label: t("reportcolumns.membersummary.carryforward"), value: "CarryForward" },
  ];

  const monthlyWinLossColumn = [
    { label: t("reportcolumns.monthlywinloss.member"), value: "Member" },
    { label: t("reportcolumns.monthlywinloss.turnover"), value: "Turnover" },
    { label: t("reportcolumns.monthlywinloss.companywinloss"), value: "CompanyWinLoss" },
    { label: t("reportcolumns.monthlywinloss.moredetails"), value: "MoreDetails" },
  ];

  const withdrawalColumn = [
    { label: t("reportcolumns.withdrawal.date"), value: "Date" },
    { label: t("reportcolumns.withdrawal.amount"), value: "Amount" },
    { label: t("reportcolumns.withdrawal.confirmedamount"), value: "ConfirmedAmount" },
    { label: t("reportcolumns.withdrawal.processingfee"), value: "ProcessingFee" },
    { label: t("reportcolumns.withdrawal.status"), value: "Status" },
  ];

  const firstTimeDepositColumn = [
    { label: t("reportcolumns.firsttimedeposit.username"), value: "Username" },
    { label: t("reportcolumns.firsttimedeposit.currency"), value: "Currency" },
    { label: t("reportcolumns.firsttimedeposit.firsttimedepositdate"), value: "FirstTimeDepositDate" },
    { label: t("reportcolumns.firsttimedeposit.amount"), value: "Amount" },
  ];

  const depositColumn = [
    { label: t("reportcolumns.deposit.date"), value: "Date" },
    { label: t("reportcolumns.deposit.amount"), value: "Amount" },
    { label: t("reportcolumns.deposit.confirmedamount"), value: "ConfirmedAmount" },
    { label: t("reportcolumns.deposit.processingfee"), value: "ProcessingFee" },
    { label: t("reportcolumns.deposit.status"), value: "Status" },
  ];

  let sumRow = {}
  let columns = [];
  let dataSource = [];


  switch (menucode) {
    case 'withdrawal':
      columns = withdrawalColumn;

      if (isGetWithdrawSuccess) {

        dataSource = withdraws?.data && Array.isArray(withdraws.data)
          ? withdraws.data.map(item => ({
            Date: moment(item.date).format('YYYY/MM/DD HH:mm:ss'),
            Amount: item.amount,
            ConfirmedAmount: item.amount - (item.processingfee || 0),
            ProcessingFee: item.processingFee != null ? item.processingFee : 0,
            Status: item.status,
          }))
          : [];
        //       console.log(dataSource);
      }
      break;
    case 'deposit':
      columns = depositColumn;
      // console.log(deposits, isGetDepositSuccess, isError, error);

      if (isGetDepositSuccess) {

        dataSource = deposits?.data && Array.isArray(deposits.data)
          ? deposits.data.map(item => ({
            Date: moment(item.date).format('YYYY/MM/DD HH:mm:ss'),
            Amount: item.amount,
            ConfirmedAmount: item.amount - (item.processingfee || 0),
            ProcessingFee: item.processingFee != null ? item.processingFee : 0,
            Status: item.status,
          }))
          : [];
        //       console.log(dataSource);
      }
      break;
    case 'member':
      columns = memberColumn;

      if (isGetDownlineSuccess) {
        dataSource = downline?.data && Array.isArray(downline.data)
          ? downline.data.map(item => ({
            Id: item.playerProfile.id,
            Login: item.playerProfile.user.username,
            RegisterDate: moment(item.playerProfile.dateCreated).format('YYYY/MM/DD HH:mm:ss'),
            Currency: process.env.REACT_APP_CURRENCY_CODE,
            SignUpSite: item.playerProfile.registerDomain,
            AccountStatus: item.playerProfile.user.status,
            FirstDepositDate: item.firstDepositDate ? moment(item.firstDepositDate).format('YYYY/MM/DD HH:mm:ss') : "-",
            Action: (
              <button     
              onClick={() => {
                handleRowClick('Action', item); 
              }}
              style={{
                backgroundColor: 'transparent',  
                color: 'black',                  
                padding: '10px 20px',            
                border: 'none',                 
                cursor: 'pointer',              
                fontSize: '14px',                
                fontWeight: 'bold',              
                textTransform: 'uppercase',      
              }}
            >
                {t("others.deposithistory")}
              </button>
            )
          }))
          : [];
        //    console.log(dataSource);
      }
      break;
    case 'firstTimeDeposit':
      columns = firstTimeDepositColumn;

      if (isGetFirstDepositDetailsSuccess) {

        dataSource = firstDeposits?.data && Array.isArray(firstDeposits.data)
          ? firstDeposits.data.map(item => ({
            Username: item.username,
            FirstTimeDepositDate: moment(item.firstDepositDate).format('YYYY/MM/DD HH:mm:ss'),
            Currency: process.env.REACT_APP_CURRENCY_CODE,
            Amount: item.firstDepositAmount
          })).filter(item => item.Amount !== null)
          : [];
        //       console.log(dataSource);
      }
      break;
    case 'monthlyCommission':
      columns = monthlyCommissionColumn;

      if (isGetMonthlyCommissionSuccess) {
        dataSource = monthlyCommission?.data && Array.isArray(monthlyCommission.data)
          ? monthlyCommission.data.map(item => ({
            Date: `${moment(item.startDate).format('YYYY/MM/DD')} - ${moment(item.endDate).format('YYYY/MM/DD')}`,
            Currency: process.env.REACT_APP_CURRENCY_CODE,
            ActiveMembers: item.activeMember ? item.activeMember : 0,
            TotalEntitled: item.totalEntitledCommission ? item.totalEntitledCommission : 0,
            WinLoss: item.winLoss ? item.winLoss : 0,
            SharedCost: item.sharedCost ? item.sharedCost : 0,
            Rate: item.rate ? item.rate : 0,
            CalculatedCommission: item.calculatedCommission ? item.calculatedCommission : 0,
            AdjustedAmount: item.adjustedAmount ? item.adjustedAmount : 0,
            Commission: item.commission ? item.commission : 0,
            CarryForward: item.carryForward ? item.carryForward : 0,
            ActualRelease: item.actualRelease ? item.actualRelease : 0,
            Status: item.status,
          }))
          : [];
      }
      break;
    case 'monthlyWinLoss':

      columns = monthlyWinLossColumn;

      if (isGetDownlineTurnoverSuccess) {

        dataSource = turnovers?.data && Array.isArray(turnovers.data)
          ? turnovers.data.map(item => ({
            Member: item.username,
            Turnover: item.turnoverAmount,
            CompanyWinLoss: item.winLoseAmount,
            PlayerProfileId: item.playerProfileId,
            MoreDetails: (
              <button     
              onClick={() => {
                handleRowClick('MoreDetails', item); 
              }}
              style={{
                backgroundColor: 'transparent',  
                color: 'black',                  
                padding: '10px 20px',            
                border: 'none',                 
                cursor: 'pointer',              
                fontSize: '14px',                
                fontWeight: 'bold',              
                textTransform: 'uppercase',      
              }}
            >
                {t("others.moredetails")}
              </button>
            )
          }))
          : [];
      }
      //console.log(dataSource);

      sumRow = {
        Member: "Total",
        Turnover: dataSource.reduce((sum, row) => sum + (row.Turnover || 0), 0),
        CompanyWinLoss: dataSource.reduce((sum, row) => sum + (row.CompanyWinLoss || 0), 0),
        MoreDetails: "",
      };

      break;
    case 'memberSummary':
      columns = memberSummaryColumn;

      if (isGetMemberSummaryReportSuccess) {

        dataSource = memberreport?.data && Array.isArray(memberreport.data)
          ? memberreport.data.map(item => ({
            Currency: "BDT",
            TotalMemberRegistered: item.totalMemberRegistered || 0,
            TotalCompanyWinLoss: item.totalCompanyWinLoss || 0,
            TotalDeposit: item.totalDeposit || 0,
            TotalWithdrawal: item.totalWithdrawal || 0,
            ValidTurnover: item.validTurnover || 0,
            Expenses: item.expenses || 0,
            CarryForward: item.lastMonthCarryForward || 0
          }))
          : [{
            Currency: "BDT",
            TotalMemberRegistered: memberreport.data.totalMemberRegistered || 0,
            TotalCompanyWinLoss: memberreport.data.totalCompanyWinLoss || 0,
            TotalDeposit: memberreport.data.totalDeposit || 0,
            TotalWithdrawal: memberreport.data.totalWithdrawal || 0,
            ValidTurnover: memberreport.data.validTurnover || 0,
            Expenses:memberreport.data.expenses || 0 ,
            CarryForward: memberreport.data.lastMonthCarryForward || 0
          }];
        //       console.log(dataSource);
      }
      break;
    default:
      columns = [];
      dataSource = [];
  }

  
  const getMostRecentMonday = () => {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
    const mondayOffset = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // Calculate offset
    const recentMonday = new Date(today);
    recentMonday.setDate(today.getDate() + mondayOffset);
    return recentMonday.toISOString().split('T')[0]; // Return in YYYY-MM-DD format
  };

  const today = new Date(); 
  const formattedToday = today.toISOString().split('T')[0];
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1); 
  const formattedYesterday = yesterday.toISOString().split('T')[0];
  const formattedMonday = getMostRecentMonday();
  const monday= new Date(formattedMonday)

  const lastWeekStartDate = new Date(monday);
  lastWeekStartDate.setDate(monday.getDate() - 7); // Last Monday

  // Calculate end date: Sunday of last week (Monday plus 6 days)
  const lastWeekEndDate = new Date(lastWeekStartDate);
  lastWeekEndDate.setDate(lastWeekStartDate.getDate() + 6); // Last Sunday

  // Format start and end dates as YYYY-MM-DD
  const formattedLastWeekStartDate = lastWeekStartDate.toISOString().split('T')[0];
  const formattedLastWeekEndDate = lastWeekEndDate.toISOString().split('T')[0];

  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1); // First day of this month
  const formattedStartOfMonth = startOfMonth.toISOString().split('T')[0];

  const setDateParameter = (columnName) => {
    switch (columnName) {
      case 'Today':

        setStartdate(formattedToday);
        setEnddate(formattedToday); 
      //  console.log(columnName, `Start: ${formattedToday}, End: ${formattedToday}`);
        break;
      case 'Yesterday':
              
        setStartdate(formattedYesterday);
        setEnddate(formattedToday);
        
      //  console.log(columnName, `Start: ${formattedYesterday}, End: ${formattedToday}`);
        break;
      case 'This Week':
        setStartdate(formattedMonday);
        setEnddate(formattedToday);
        
       // console.log(columnName, `Start: ${formattedMonday}, End: ${formattedToday}`);
        break;
      case 'Last Week':
      
        setStartdate(formattedLastWeekStartDate);
        setEnddate(formattedLastWeekEndDate);
        break; 
      //  console.log(columnName, `Start: ${formattedLastWeekStartDate}, End: ${formattedLastWeekEndDate}`);
      case 'This Month':
        setStartdate(formattedStartOfMonth);
        setEnddate(formattedToday);
        break;
        //console.log(columnName, `Start: ${formattedStartOfMonth}, End: ${formattedToday}`);
      default:
        return null; // Return a default value or handle as needed
    }
  };

  //pagination for table
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 8));
    setPage(0); // Reset to the first page
  };

  const paginatedData = dataSource.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  console.log("teamSize:", teamSize);


  return (
    <div>
      {menucode !== "teamSize" ? (
        <TableContainer style={{ border: '1px solid #ccc', borderRadius: '5px' }}>
          <Box className='draggable-table-container'>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell key={index} style={{ textWrap: "nowrap", backgroundColor: '#d3d3d3', fontWeight: 'bold' }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.length > 0 ? (
                  paginatedData.map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {columns.map((column, colIndex) => (
                        <TableCell key={colIndex} sx={{ textWrap: "nowrap", color: "black", backgroundColor: '#f5f5f5' }}>
                          {clickableColumns.includes(column.value) ? (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleRowClick(column.value, row)}
                            >
                              {row[column.value]}
                            </Button>
                          ) : (
                            row[column.value]
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length + 1} align="center">
                    {t("others.nodata")}
                    </TableCell>
                  </TableRow>
                )}
                {/* Conditionally render the sum row only for monthlyWinLoss case */}
                {menucode === 'monthlyWinLoss' && (
                  <TableRow>
                    {columns.map((column, colIndex) => (
                      <TableCell key={colIndex} sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0' }}>
                        {sumRow[column.value] || ""}
                      </TableCell>
                    ))}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
          {/* Pagination Component */}
          <CustomPagination
            component="div"
            count={dataSource.length} // Total number of rows
            page={page} // Current page number
            onPageChange={handleChangePage} // Function to handle page change
            rowsPerPage={rowsPerPage} // Rows per page
            onRowsPerPageChange={handleChangeRowsPerPage} // Function to change rows per page
            sx={{
              display: 'none',
            }}
          />
        </TableContainer>
      ) : (
      <TableContainer style={{ border: '1px solid #ccc', borderRadius: '5px' }} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ backgroundColor: '#d3d3d3', fontWeight: 'bold' }}>{t("teamsize.teamsize")}</TableCell>
              {columnsTeamSizeDisplay.map((column, index) => (
                <TableCell style={{ backgroundColor: '#d3d3d3', fontWeight: 'bold' }} key={index}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
  {teamSize.Today && Object.keys(teamSize.Today).map((rowIndex) => (
    <TableRow key={rowIndex}>
      <TableCell sx={{ color: "black", backgroundColor: '#f5f5f5' }}>
        {rowsTeamSizeDisplay[rowIndex]}
      </TableCell>
      {columnsTeamSizeKey.map((column, colIndex) => (
        <TableCell sx={{ color: "black", backgroundColor: '#f5f5f5', textAlign: 'center' }} key={colIndex}>
          {teamSize[column] && teamSize[column][rowIndex] !== undefined ? (
            clickableRows.includes(rowsTeamSizeKey[rowIndex]) ? (
              <Button
                variant="contained"
                onClick={() => {
                  setDateParameter(column);
                  handleRowClick2(rowsTeamSizeKey[rowIndex], column);
                }}
                sx={{ padding: 0, minWidth: 'auto', textTransform: 'none', width: '50%' }}
              >
                {teamSize[column][rowIndex]}
              </Button>
            ) : (
              teamSize[column][rowIndex]
            )
          ) : (
            <span>N/A</span> // Or some placeholder for missing data
          )}
        </TableCell>
      ))}
    </TableRow>
  ))}
</TableBody>
        </Table>
      </TableContainer>)}
      <AffiliateReportPopUp 
        open={open} 
        handleClose={handleClose} 
        selectedData={selectedData} 
        startDate={startdate}
        endDate={enddate}
        buttonClicked={buttonClicked}
      />
    </div >
  );
};

export default AffiliateReportTables;