import React from 'react';
import { useState } from 'react';
import '../components/Affiliate/reportstyles.css'
import * as Constant from "../features/constant";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  useMediaQuery,
  useTheme,
  Box,
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AffiliateSideNav from '../components/Affiliate/AffiliateSideNav';
import AffiliateWallet from '../components/Affiliate/AffiliateWallet';

const AffiliateTransfer = () => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const handleTransfer = (id) => {
    //To be replaced with custom transfer popup

    console.log(`Transfer button clicked for ID: ${id}`);

  };

  const [showTable, setShowTable] = useState(false);
  const [data, setData] = useState([]);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleSearch = () => {

    // Fetch data from database and use setData to populate table, currently using static data

    setData([
      { id: 1, login: 'User1', registerDate: '2024-09-01 00:43:45' },
      { id: 2, login: 'User2', registerDate: '2024-09-01 01:41:36' },
      { id: 3, login: 'User3', registerDate: '2024-09-01 01:59:02' }
    ]);

    setShowTable(true);
  };

  return (
    <>
      {/* Desktop view  */}
      {!mobileView && (
        <Box className='container' pt={{ xs: 7, sm: 0, lg: 0, xl: 0 }}>
          <AffiliateSideNav />
          <div className='menuWrapper'>
            <AffiliateWallet />
            {/* Date picker field, to be updated with functions after getting details */}
            <div className='datePickerContainer'>
              <label htmlFor="from-date">From</label>
              <input type="date" id="from-date" name="from-date" />
              <label htmlFor="to-date">To</label>
              <input type="date" id="to-date" name="to-date" />
              <input type="text" placeholder="Login" />
              <select>
                <option value="member">Member</option>
                <option value="admin">Admin</option>
              </select>
              <button className='searchButton' onClick={handleSearch} >Search</button>
            </div>
            <div className='report-container'>
              {/* Condition where table will only show after pressing search button */}
              {showTable && (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead style={{ backgroundColor: '#fff' }}>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Login</TableCell>
                        <TableCell>Register Date</TableCell>
                        <TableCell align="right">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((row) => (
                        <TableRow key={row.id} style={{ backgroundColor: '#fff' }}>
                          <TableCell>{row.id}</TableCell>
                          <TableCell>{row.login}</TableCell>
                          <TableCell>{row.registerDate}</TableCell>
                          <TableCell align="right">
                            <Button
                              variant="contained"
                              onClick={() => handleTransfer(row.id)}
                              style={{ backgroundColor: 'white', color: '#fff' }}
                            >
                              Transfer
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </div>
          </div>
        </Box>
      )}
      {/*Mobile view */}
      {mobileView && (
        <Box className='container' pt={{ xs: 7, sm: 0, lg: 0, xl: 0 }}>
          <div className='menuWrapper'>
            <AffiliateWallet />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid container spacing={2} paddingTop={2}>

                <Grid item xs={12}>
                  <DatePicker
                    label="From *"
                    value={fromDate}
                    onChange={(newValue) => setFromDate(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        InputLabelProps={{
                          style: { color: 'white' },
                        }}
                        sx={{
                          maxWidth: "100%",
                          input: {
                            color: 'white',
                          }
                        }}
                        PopperProps={{
                          sx: {
                            '& .MuiPaper-root': {
                              backgroundColor: '#fff',  // White background for calendar pop-up
                              color: 'white',  // Black text for the calendar
                            },
                            '& .MuiPickersDay-root': {
                              color: 'white',  // Default day text color (black)
                              '&.Mui-selected': {
                                backgroundColor: 'white',  // Black background for selected date
                                color: '#fff',  // White text for selected date
                              },
                            },
                            '& .MuiIconButton-root': {
                              color: 'white',  // Black color for navigation arrows and icons
                            },
                          },
                        }}

                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <DatePicker
                    label="To *"
                    value={toDate}
                    onChange={(newValue) => setToDate(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        InputLabelProps={{
                          style: { color: 'white' },
                        }}
                        sx={{
                          maxWidth: "100%",
                          input: {
                            color: 'white',
                          }
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Login"
                    variant="outlined"
                    sx={{
                      maxWidth: "100%",
                      input: {
                        color: 'white',
                      }
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },
                    }}
                  />
                </Grid>

                <Grid item xs={12} >
                  <FormControl fullWidth>
                    <InputLabel id="bank-label" sx={{ color: 'white' }}>Transfer Target *</InputLabel>
                    <Select
                      labelId="bank-label"
                      id="membertype"
                      label="Transfer Target *"
                      defaultValue=""
                      sx={{
                        borderColor: 'white',
                        color: 'white',
                        maxWidth: '100%',
                      }}

                    >
                      <MenuItem value="">
                        <em>Please Select</em>
                      </MenuItem>
                      {/*bank selection currently static, can be changed to json later*/}
                      <MenuItem value="bank1">Member</MenuItem>
                      <MenuItem value="bank2">Sub Affiliate</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Button

                    variant="contained"
                    onClick={handleSearch}
                    sx={{
                      backgroundColor: '#EE2842',
                      fontWeight: "bold",
                      color: '#fff',
                      height: '48px',
                      maxWidth: '100%',
                      marginBottom: '10px'
                    }}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </LocalizationProvider>
            <div className='report-container'>
              {/* Condition where table will only show after pressing search button */}
              {showTable && (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead style={{ backgroundColor: '#fff' }}>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Login</TableCell>
                        <TableCell>Register Date</TableCell>
                        <TableCell align="right">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((row) => (
                        <TableRow key={row.id} style={{ backgroundColor: '#fff' }}>
                          <TableCell>{row.id}</TableCell>
                          <TableCell>{row.login}</TableCell>
                          <TableCell>{row.registerDate}</TableCell>
                          <TableCell align="right">
                            <Button
                              variant="contained"
                              onClick={() => handleTransfer(row.id)}
                              style={{ backgroundColor: 'white', color: '#fff' }}
                            >
                              Transfer
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </div>
          </div>
        </Box>

      )}
    </>
  );
};

export default AffiliateTransfer;
