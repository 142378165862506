import { apiSlice } from "./apiSlice";

export const transactionApiSlice = apiSlice.injectEndpoints({
  tagTypes: [
    "Deposit",
    "DepositDetails",
    "Withdraw",
    "WithdrawDetails",
    "History",
  ],
  endpoints: (builder) => ({
    deposit: builder.mutation({
      query: ({ deposit, paymentGateway }) => ({
        url: `/transaction/api/v1/deposit?paymentGateway=${paymentGateway}`,
        method: "POST",
        body: deposit,
      }),
      providesTags: ["Deposit"],
    }),
    getDepositDetails: builder.query({
      query: () => "/transaction/api/v1/depositDetails",
      providesTags: ["DepositDetails"],
    }),
    getHistory: builder.mutation({
      query: (queryBody) => ({
        url: "/transaction/api/v1/history",
        method: "POST",
        body: queryBody,
      }),
      providesTags: ["History"],
    }),
    withdraw: builder.mutation({
      query: (withdraw) => ({
        url: "/cust/api/v1/withdraw",
        method: "POST",
        body: withdraw,
      }),
      providesTags: ["Withdraw"],
    }),
    getWithdrawDetails: builder.query({
      query: () => "/cust/api/v1/withdrawDetails",
      providesTags: ["WithdrawDetails"],
    }),
  }),
});

export const {
  useDepositMutation,
  useGetDepositDetailsQuery,
  useGetHistoryMutation,
  useWithdrawMutation,
  useGetWithdrawDetailsQuery,
} = transactionApiSlice;
