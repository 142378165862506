import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials, logout } from "../slices/authSlice";
import { encode } from "base-64";
import i18n from "i18next";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  credentials: "include",
  prepareHeaders: (headers, { getState, endpoint, extra }) => {
    headers.set("Accept-Language", i18n.language);
    const token = getState().auth.token;
    if (endpoint !== "login") {
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
    } else {
      headers.set(
        "authorization",
        `Basic ${encode(process.env.REACT_APP_BASIC_AUTH)}`
      );
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  // args = request url, method, body
  // api = signal, dispatch, getState()
  // extraOptions = custom like {shout: true }
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    // send refresh token to get new access token
    let refreshToken = api.getState().auth.refreshToken;

    let formData = new FormData();
    formData.append("refresh_token", refreshToken);
    formData.append("grant_type", "refresh_token");

    const refreshResult = await baseQuery(
      { url: "/login", method: "POST", body: formData },
      { ...api, endpoint: "login" },
      extraOptions
    );

    if (refreshResult?.data) {
      // store the new token
      api.dispatch(setCredentials({ ...refreshResult.data }));
    } else {
      api.dispatch(logout());
    }
    // retry the original query with new access token
    result = await baseQuery(args, api, extraOptions);

        // Check for success and store data in localStorage
        if (result?.data) {
          console.log('API call successful, storing data in localStorage:', result.data);
          // Convert the data to a string and save it
          localStorage.setItem('apiProfileResponseData', JSON.stringify(result.data));

          // If want to manually set and access single field of data in localstorage
          localStorage.setItem('username', result.data.data?.username);
        } else if (result?.error) {
          console.error('API call failed:', result.error);
        }
    
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});
