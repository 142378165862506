import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Grid,
  Stack,
  Box,
  useMediaQuery,
  useTheme,
  Divider,
  ListItemButton,
  Typography,
  Button,
  Container,
  IconButton,
  InputLabel,
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, Link, Tooltip
} from "@mui/material";
import "../components/Affiliate/style.css"
import * as Constant from "../features/constant";
import AffiliateWallet from "../components/Affiliate/AffiliateWallet";

// const PATH = process.env.PUBLIC_URL;

const AffiliateTransferMenu = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [seconds, setSeconds] = useState(null);
  const searchParams = new URLSearchParams(location.search);
  const paramTab = searchParams.get("tab");

  const handleDepositClick = () => {
    navigate(Constant.getPath('/affiliatedeposit'));
  };
  const handleTransferClick = () => {
    navigate(Constant.getPath('/affiliatetransfer'));
  };
  const handleWithdrawalClick = () => {
    navigate(Constant.getPath('/affiliatewithdraw'));
  };

  const handleReportClick = () => {
    navigate(Constant.getPath('/affiliatereport'));
  };

  const handleProfileClick = () => {
    navigate(Constant.getPath('/affiliateprofile'));
  };

  const handlePasswordClick = () => {
    navigate(Constant.getPath('/affiliatechangepassword'));
  };

  const handleBankClick = () => {
    navigate(Constant.getPath('/affiliatebankdetails'));
  };

  return (
    <>
      {/*Mobile view */}
      {mobileView && (
        <Box className='container' pt={{ xs: 7, sm: 0, lg: 0, xl: 0 }}>
          <div className='menuWrapper'>
            <AffiliateWallet />

            {location?.state?.screen?.toLowerCase() === "fund" && (
              <div className='report-container'>
                <Box sx={{ boxShadow: '0 4px 10px rgba(0, 0, 0, 0.5)', borderRadius: "8px", margin: '5px' }}>
                  <Grid container display={'flex'} alignContent={"center"} justifyContent="center" spacing={2} sx={{ maxWidth: '400px', marginTop: '10px' }}>
                   {/*
                    <Grid item xs={6}>
                      <Box display="flex" justifyContent="center" alignItems="center" sx={{ backgroundColor: '#313338', borderRadius: '8px', padding: '20px', textAlign: 'center', height: '120px' }}>
                        <Button className="menu-button" onClick={handleDepositClick}>
                          <img src="/assets/img/wallet/wallet_card/Deposit.png" alt="Deposit" />
                          <p>Deposit</p>
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box display="flex" justifyContent="center" alignItems="center" sx={{ backgroundColor: '#313338', borderRadius: '8px', padding: '20px', textAlign: 'center', height: '120px' }}>
                        <Button className="menu-button" onClick={handleTransferClick}>
                          <img src="/assets/img/wallet/wallet_card/Transaction.png" alt="Transfer" />
                          <p>Transfer</p>
                        </Button>
                      </Box>
                    </Grid>
                    */}
                    <Grid item xs={6}>
                      <Box display="flex" justifyContent="center" alignItems="center" sx={{ backgroundColor: '#313338', borderRadius: '8px', padding: '20px', textAlign: 'center', height: '120px' }}>
                        <Button className="menu-button" onClick={handleWithdrawalClick}>
                          <img src="/assets/img/wallet/wallet_card/Withdraw.png" alt="Withdrawal" />
                          <p>{t("transfermenu.withdrawal")}</p>
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box display="flex" justifyContent="center" alignItems="center" sx={{ backgroundColor: '#313338', borderRadius: '8px', padding: '20px', textAlign: 'center', height: '120px' }}>
                        <Button className="menu-button" onClick={handleReportClick}>
                          <img src="/assets/img/wallet/wallet_card/Bet Record.png" alt="Report" />
                          <p>{t("transfermenu.report")}</p>
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            )}

            {location?.state?.screen?.toLowerCase() === "account" && (
              <div className='report-container'>
                <Box sx={{ boxShadow: '0 4px 10px rgba(0, 0, 0, 0.5)', borderRadius: "8px", margin: '5px' }}>
                  <Grid container justifyContent="center" spacing={2} sx={{ maxWidth: '400px', marginTop: '10px' }}>
                    <Grid item xs={6}>
                      <Box display="flex" justifyContent="center" alignItems="center" sx={{ backgroundColor: '#313338', borderRadius: '8px', padding: '20px', textAlign: 'center', height: '120px' }}>
                        <Button className="menu-button" onClick={handleProfileClick}>
                          <img src="/assets/img/wallet/wallet_card/user.png" alt="Profile" />
                          <p>{t("transfermenu.myprofile")}</p>
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box display="flex" justifyContent="center" alignItems="center" sx={{ backgroundColor: '#313338', borderRadius: '8px', padding: '20px', textAlign: 'center', height: '120px' }}>
                        <Button className="menu-button" onClick={handlePasswordClick}>
                          <img src="/assets/img/wallet/wallet_card/Change Password.png" alt="Change Password" />
                          <p>{t("transfermenu.changepassword")}</p>
                        </Button>
                      </Box>
                    </Grid>

                    <Grid item xs={6}>
                      <Box display="flex" justifyContent="center" alignItems="center" sx={{ backgroundColor: '#313338', borderRadius: '8px', padding: '20px', textAlign: 'center', height: '120px' }}>
                        <Button className="menu-button" onClick={handleBankClick}>
                          <img src="/assets/img/wallet/wallet_card/Bank Account.png" alt="Bank" />
                          <p>{t("transfermenu.bankdetails")}</p>
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={6} />
                  </Grid>
                </Box>
              </div>
            )}
          </div>
        </Box >

      )}
    </>
  );
};

export default AffiliateTransferMenu;
