import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  CardGiftcard,
  LocalAtm,
  Dehaze,
  Groups,
  Person,
  Description,
  Margin,
} from "@mui/icons-material";
import {
  Box,
  useMediaQuery,
  useTheme,
  AppBar,
  BottomNavigation,
  BottomNavigationAction, Grid, Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { selectCurrentToken } from "../../features/slices/authSlice";
import { selectAlertIsOpen } from "../../features/slices/alertSlice";

import { useGetCommonBackgroundQuery } from "../../features/api/publicApiSlice";

import MobileHeader from "../Header/MobileHeader";
import SideNav from "../common/SideNav";
import SnackbarAlert from "../common/SnackbarAlert";
import * as Constant from "../../features/constant";
import Drawer from "../common/Drawer";
import { useGetProfileQuery } from "../../features/api/userApiSlice";
import SpinWheelSideNavBar from "../../components/common/SpinWheelSideNavBar";
import DesktopHeaderAffiliate from "../Header/DesktopHeaderAffiliate";
import FooterAffiliate from "../Footer/FooterAffiliate";

const LayoutAffiliate = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const token = useSelector(selectCurrentToken);
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const alertIsOpen = useSelector(selectAlertIsOpen);
  const [showPopup, setShowPopup] = useState(true);

  // const {
  //   data: commonBackground,
  //   isLoading: isCommonBackgroundLoading,
  //   isSuccess: isCommonBackgroundSuccess,
  // } = useGetCommonBackgroundQuery({ prefetch: true });

  const handleClose = () => {
    setShowPopup(false);
  };

  const triggerLiveChat = () => {
    if (
      typeof window.LiveChatWidget !== "undefined" &&
      window.LiveChatWidget.call
    ) {
      window.LiveChatWidget.call("maximize");
    }
  };

  const {
    data: user,
    isFetching: isUserFetching,
    isLoading: isUserLoading,
  } = useGetProfileQuery({ prefetch: true });

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [navigationValue, setNavigationValue] = useState(0);

  const handleButtonClick = () => {
    // Toggle the state to open or close the drawer
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleNavigationChange = (event, newValue) => {
    setNavigationValue(newValue); // Update the active value
    if (newValue === 0) {
      setIsDrawerOpen((prev) => !prev); // Toggle the drawer
    } else if (newValue === 1) {
      if (token) {
        navigate(Constant.getPath("/affiliatetransfermenu"), {
          state: { screen: "fund" },
        });
      } else {
        navigate(Constant.getPath("/login"));
      }
    } else if (newValue === 2) {
      if (token) {
        navigate(Constant.getPath("/affiliatetransfermenu"), {
          state: { screen: "account" },
        });
      } else {
        navigate(Constant.getPath("/login"));
      }
    }
  };

  return (

    <Box
      display="flex"
      flexDirection="column"
      backgroundColor="black"
      minHeight="100vh"
    >
      {alertIsOpen && <SnackbarAlert />}
      {/* {<SideNav/>} */}
      {/* {<SpinWheelSideNavBar/>} */}
      {/*<div>*/}
      {/*  <Grid container>*/}
      {/*    <Grid item style={{*/}
      {/*      display: showPopup ? 'flex' : 'none',*/}
      {/*      flexDirection: 'row',*/}
      {/*      alignItems: 'center',*/}
      {/*      justifyContent: 'center',*/}
      {/*      backgroundColor: '#ee2842',*/}
      {/*      position: 'fixed',*/}
      {/*      top: 0,*/}
      {/*      left: 0,*/}
      {/*      width: '100%',*/}
      {/*      padding: '3px',*/}
      {/*      zIndex: 9999, // Ensure the popup appears above other content*/}
      {/*      textAlign: 'center',*/}
      {/*    }}>*/}
      {/*      <Typography style={{*/}
      {/*        color: 'white',*/}
      {/*        position: 'relative',*/}
      {/*        zIndex: 1,*/}
      {/*        fontSize: '10px',*/}
      {/*        marginRight: '5px',*/}
      {/*        fontWeight: 'bold',*/}
      {/*      }}>*/}
      {/*        {t("header.desc")}*/}
      {/*      </Typography>*/}
      {/*      <button style={{*/}
      {/*        background: theme.palette.button.secondary.main,*/}
      {/*        color: 'white',*/}
      {/*        width: '80px',*/}
      {/*        height: '24px',*/}
      {/*        bottom: '5px',*/}
      {/*        marginRight: '25px',*/}
      {/*        borderRadius: '5px', // Add border-radius for curved corners*/}
      {/*        borderColor: 'transparent',*/}
      {/*        zIndex: 0,*/}
      {/*        fontSize: '10px',*/}
      {/*        fontWeight: 'bold',*/}
      {/*      }}*/}
      {/*              onClick={handleButtonClick}*/}
      {/*      >*/}
      {/*        {t("header.button")}*/}
      {/*      </button>*/}
      {/*      <button style={{*/}
      {/*        border: 'none',*/}
      {/*        background: 'none',*/}
      {/*        cursor: 'pointer',*/}
      {/*        color: 'white',*/}
      {/*        position: 'absolute',*/}
      {/*        top: '10px',*/}
      {/*        right: '5px',*/}
      {/*        fontWeight: 'bold',*/}
      {/*        fontSize: '12px',*/}
      {/*      }} onClick={handleClose}>X*/}
      {/*      </button>*/}
      {/*    </Grid>*/}
      {/*  </Grid>*/}
      {/*</div>*/}
      <header>
        {mobileView ? (
          <>
            <MobileHeader
            // background={
            //   commonBackground?.mobileBackground[0]?.thumbnailUrl
            // }
            />
          </>
        ) : (
          <DesktopHeaderAffiliate
          // background={commonBackground?.webBackground[0]?.thumbnailUrl}
          />
        )}
      </header>
      <Box flex={1}>
        <Outlet />
      </Box>
      {!mobileView && (
        <footer>
          <FooterAffiliate
          // background={commonBackground?.webBackground[1]?.thumbnailUrl}
          // contactDetails={commonBackground?.result}
          />
        </footer>
      )}

      {mobileView && (
        <AppBar
          position="fixed"
          style={{
            top: "auto",
            bottom: 0,
          }}
        >
          <BottomNavigation
            sx={{
              backgroundColor: '#212529',
            }}
            showLabels
            // value={value}
            value={navigationValue} 
            onChange={handleNavigationChange} 
          >
            <BottomNavigationAction
              sx={{
                color: "white", "& .MuiSvgIcon-root": { color: theme.palette.primary.main },
                width: "20%",
                minWidth: "20%",
                maxWidth: "20%"
              }}
              label={t("footer.menu")}
              icon={<Dehaze />}
            //  onClick={handleButtonClick}
            />
            <BottomNavigationAction
              sx={{
                color: "white", "& .MuiSvgIcon-root": { color: theme.palette.primary.main },
                width: "20%",
                minWidth: "20%",
                maxWidth: "20%"
              }}
              label={t("footer.fund")}
              icon={<Groups />}
            />
            {/* <BottomNavigationAction
              sx={{
                color: "white", "& .MuiSvgIcon-root": { color: theme.palette.primary.main },
                width: "20%",
                minWidth: "20%",
                maxWidth: "20%"
              }}
              label={t("footer.affiliatereport")}
              icon={<Description />}
            /> */}
            <BottomNavigationAction
              sx={{
                color: "white", "& .MuiSvgIcon-root": { color: theme.palette.primary.main },
                width: "20%",
                minWidth: "20%",
                maxWidth: "20%"
              }}
              label={t("footer.account")}
              icon={<Person />}
            />
          </BottomNavigation>

          {isDrawerOpen && <Drawer user={user?.result} />}

        </AppBar>
      )}
    </Box>
  );
};

export default LayoutAffiliate;
