import React from 'react';
import {
  TablePagination,
  Box,
  PaginationItem,
  Pagination as MuiPagination
} from '@mui/material';

const CustomPagination = ({ count, page, rowsPerPage, onPageChange, onRowsPerPageChange,backgroundColor }) => {
  const handleChange = (event, value) => {
    onPageChange(event, value - 1); // Adjust for zero-indexed page
  };

  return (
    <Box sx={{ position: "sticky", right: 0, left: 0, display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'flex-end',  backgroundColor: backgroundColor || 'white', padding: '10px', borderTop: 'none' }}>
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onRowsPerPageChange={onRowsPerPageChange}
        sx={{
          display: 'none', // Hide the default TablePagination controls
        }}
      /> */}
      <MuiPagination
        count={Math.ceil(count / rowsPerPage)} // Calculate total pages
        page={page + 1} // Adjust for zero-indexed page
        onChange={handleChange}
        showFirstButton
        showLastButton
        siblingCount={2} // Change this number to show more buttons
        boundaryCount={1} // Buttons on the boundaries (start and end)
        renderItem={(item) => {
          // Remove the First and Last buttons
          if (item.type === 'first' || item.type === 'last') {
            return null;
          }
          return (
            <PaginationItem {...item} />
          );
        }}
        sx={{
          '& .MuiPaginationItem-root': {
            border: '1px solid #ccc', // Border for all pagination items
            borderRadius: '50%', // Circular shape
            margin: '0 2px',
            width: '30px', // Adjust width for circles
            height: '30px', // Adjust height for circles
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&.Mui-selected': {
              backgroundColor: '#d0d0d0', // Selected page background
              fontWeight: 'bold',
            },
            '&:hover': {
              backgroundColor: '#e0e0e0', // Hover effect for pagination items
            },
            '&.MuiPaginationItem-previousNext': {
              color: 'black !important', // Set arrow color to black
              '& svg': {
                color: 'black !important', // Change the SVG color for arrows
              },
            },
            '& .MuiPaginationItem-first, & .MuiPaginationItem-last': {
              color: 'black !important', // Set color for First and Last buttons
              '& svg': {
                color: 'black !important', // Change the SVG color for First/Last arrows
                fill: 'black !important', // Change the SVG color for First/Last arrows
              },
            },
          }
        }}
      />
    </Box>
  );
};

export default CustomPagination;
