import React, { useEffect, useRef } from 'react';
import '../components/Affiliate/reportstyles.css'
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  Button,
  InputLabel,
  Paper,
  Link,
  useMediaQuery,
  useTheme,
  Typography,
  InputAdornment,
  IconButton,
} from '@mui/material';
import {
  useGetProfileQuery,
  useGetShareLinkQuery,
} from "../features/api/userApiSlice";
import AffiliateSideNav from '../components/Affiliate/AffiliateSideNav';
import AffiliateWallet from '../components/Affiliate/AffiliateWallet';
import { useTranslation } from "react-i18next";


const AffiliateProfile = () => {

  const navigate = useNavigate();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const {
    data: user,
    isFetching: isUserFetching,
    isLoading: isUserLoading,
  } = useGetProfileQuery({ prefetch: true });

  const {
    data: shareLink,
    isLoading: isShareLinkLoading,
    isError: isShareLinkError,
    isSuccess: isShareLinkSuccess,
    error: shareLinkError,
  } = useGetShareLinkQuery();

  return (
    <>
      <Box className='container' pt={{ xs: 7, sm: 0, lg: 0, xl: 0 }}>
        <AffiliateSideNav />
        <div className='menuWrapper'>
          <AffiliateWallet />

          <div className='report-container'>
            <Grid container spacing={2} paddingTop={2}>
              <Grid item xs={12} sm={6}>
                <InputLabel sx={{ color: 'white' }}>{t("affiliateprofile.email")}</InputLabel>
                <Paper sx={{
                  padding: '16px', color: "white", border: '1px solid #6A6A6A', backgroundColor: '#6A6A6A', maxWidth: {
                    xs: "100%",
                    md: "80%"
                  },
                }}>
                  {user?.data?.email}
                </Paper>
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel sx={{ color: 'white' }}>{t("affiliateprofile.fullname")}</InputLabel>
                <Paper sx={{
                  padding: '16px', color: "white", border: '1px solid #6A6A6A', backgroundColor: '#6A6A6A', maxWidth: {
                    xs: "100%",
                    md: "80%"
                  },
                }}>
                  {user?.data?.fullname}
                </Paper>
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel sx={{ color: 'white' }}>{t("affiliateprofile.contact")}</InputLabel>
                <Paper sx={{
                  padding: '16px', color: "white", border: '1px solid #6A6A6A', backgroundColor: '#6A6A6A', maxWidth: {
                    xs: "100%",
                    md: "80%"
                  },
                }}>
                  {user?.data?.mobileNumber}
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel sx={{ color: 'white' }}>{t("affiliateprofile.currency")}</InputLabel>
                <Paper sx={{
                  padding: '16px', color: "white", border: '1px solid #6A6A6A', backgroundColor: '#6A6A6A', maxWidth: {
                    xs: "100%",
                    md: "80%"
                  },
                }}>
                  BDT
                </Paper>
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel sx={{ color: 'white' }}>{t("affiliateprofile.username")}</InputLabel>
                <Paper sx={{
                  padding: '16px', color: "white", border: '1px solid #6A6A6A', backgroundColor: '#6A6A6A', maxWidth: {
                    xs: "100%",
                    md: "80%"
                  },
                }}>
                  {user?.data?.username}
                </Paper>
              </Grid>

              <Grid item xs={12}>
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel sx={{ color: 'white' }}>{t("affiliateprofile.affdomain")}</InputLabel>
                <Paper sx={{
                  padding: '16px', color: "white", border: '1px solid #6A6A6A', backgroundColor: '#6A6A6A', maxWidth: {
                    xs: "100%",
                    md: "80%"
                  },
                }}>
                  {t("affiliateprofile.nodomain")}
                </Paper>
              </Grid>

              <Grid item xs={12} sm={6} mt={{ xs: 1.5 }} sx={{ paddingRight: 3 }} >
                <InputLabel sx={{ color: 'white' }}>{t("affiliateprofile.afflink")}</InputLabel>
                <Box mt={2} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <Typography >
                    <InputLabel sx={{ color: 'white' }}>{t("affiliateprofile.home")} </InputLabel>
                    <Link
                      href="shareLink?.result?.url"
                      target="_blank"
                      rel="noopener"
                      sx={{ textDecoration: 'underline', color: 'primary.main' }}
                    >
                      {shareLink?.result?.url}
                    </Link>
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#EE2842',
                      fontWeight: 'bold',
                      color: '#fff',
                      padding: "10px 20px",
                    }}
                  >
                    {t("affiliateprofile.share")}
                  </Button>
                </Box>

                <Box mt={3} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <Typography>
                    <InputLabel sx={{ color: 'white' }}>{t("affiliateprofile.registration")}</InputLabel>
                    <Link
                      href="shareLink?.result?.url"
                      target="_blank"
                      rel="noopener"
                      sx={{ textDecoration: 'underline', color: 'primary.main' }}
                    >
                      {shareLink?.result?.url}
                    </Link>
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#EE2842',
                      fontWeight: 'bold',
                      color: '#fff',
                      padding: "10px 20px",
                    }}
                  >
                    {t("affiliateprofile.share")}
                  </Button>
                </Box>
              </Grid>
            </Grid>

          </div>
        </div>
      </Box >
    </>
  );
};

export default AffiliateProfile;
