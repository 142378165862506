import React from "react";
import { useEffect ,useState} from "react";
import { Container, Box, useTheme, Typography,IconButton, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import TelegramIcon from '@mui/icons-material/Telegram';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import "../utils/en.json";
import { useGetContactUsQuery } from "../features/api/publicApiSlice";

const AffiliateContactUs = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [telegramURL, setTelegramURL] = useState('');
  const [emailURL, setEmailURL] = useState('');
  const [facebookURL, setFacebookURL] = useState('');
  const [whatsappURL, setWhatsappURL] = useState('');
  const [instaURL, setInstaURL] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const {
    data: contactUs,
    isSuccess: isGetContactUsSuccess,
  } = useGetContactUsQuery();

useEffect(() => {

  if (contactUs && contactUs.data){

    const email = contactUs.data[0]?.contactDetails.email.url;
    const cleanEmail = email.replace("mailto:", "");
    const whatsapp = contactUs.data[0]?.contactDetails.whatsapp.url;
  setTelegramURL(contactUs.data[0]?.contactDetails.telegram.url);
  setEmailURL(cleanEmail);
  setFacebookURL(contactUs.data[0]?.contactDetails.facebook.url);
  setWhatsappURL(contactUs.data[0]?.contactDetails.whatsapp.url);
  setInstaURL(contactUs.data[0]?.contactDetails.instagram.url);
  setPhoneNumber(whatsapp.split("/").pop());
  }
}, [contactUs]);



  return (
    <Container sx={{ color: "white", overflowX: 'hidden' }}>
      <Box my={4} pt={{ xs: 7, sm: 0, lg: 0, xl: 0 }}  sx={{ textAlign: 'center' }} >
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
          sx={{ fontSize: '1.5rem' }}
        >
          {t("contactus.title")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1.1rem' }}>
        {t("contactus.desc")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1.1rem' }}>
        {t("contactus.desc2")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1.1rem' }}>
        {t("contactus.desc3")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1.1rem' }}>
        {t("contactus.desc4")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1.1rem' }}>
        {t("contactus.desc5")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1.1rem' }}>
        {t("contactus.desc6")}
        </Typography>
        <br />
      </Box>
      <Box>
      <Grid container spacing={2} justifyContent="center">
        {/* Telegram */}
        
        <Grid item xs={12} sm={4} textAlign="center">
          <IconButton
            component="a"
            href={telegramURL}// Replace with your Telegram link
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: '#0088cc' }}
          >
            <TelegramIcon fontSize="large" sx={{ fontSize: '6rem' }} />
          </IconButton>
          <Typography variant="body2" sx={{ fontSize: '1.1rem' }}>{telegramURL}</Typography>
        </Grid>

        {/* Instagram */}
        <Grid item xs={12} sm={4} textAlign="center">
          <IconButton
            component="a"
            href={instaURL} // Replace with your Instagram link
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: '#E1306C' }}
          >
            <InstagramIcon fontSize="large" sx={{ fontSize: '6rem' }} />
          </IconButton>
          <Typography variant="body2" sx={{ fontSize: '1.1rem' }}>{instaURL}</Typography>
        </Grid>

        {/* Facebook */}
        <Grid item xs={12} sm={4} textAlign="center">
          <IconButton
            component="a"
            href={facebookURL}// Replace with your Facebook link
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: '#3b5998' }}
          >
            <FacebookIcon fontSize="large" sx={{ fontSize: '6rem' }} />
          </IconButton>
            <Typography variant="body2" component="div"
              sx={{
                fontSize: '1.1rem',
                overflowWrap: 'break-word',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
              }}>
              {facebookURL}
            </Typography>
          </Grid>

        {/* WhatsApp */}
        <Grid item xs={12} sm={4} textAlign="center">
          <IconButton
            component="a"
            href={whatsappURL} // Replace with your WhatsApp number and message
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: '#25D366' }}
          >
            <WhatsAppIcon fontSize="large" sx={{ fontSize: '6rem' }} />
          </IconButton>
          <Typography variant="body2" sx={{ fontSize: '1.1rem' }}>{whatsappURL}</Typography>
        </Grid>

        {/* Email */}
        <Grid item xs={12} sm={4} textAlign="center">
          <IconButton
            component="a"
            href={emailURL} // Replace with your email
            sx={{ color: '#D44638' }}
          >
            <EmailIcon fontSize="large" sx={{ fontSize: '6rem' }} />
          </IconButton>
          <Typography variant="body2" sx={{ fontSize: '1.1rem' }}>{emailURL}</Typography>
        </Grid>

        {/* Phone */}
        <Grid item xs={12} sm={4} textAlign="center">
          <IconButton
            component="a"
            href={phoneNumber} // Replace with your phone number
            sx={{ color: '#4CAF50' }}
          >
            <PhoneIcon fontSize="large" sx={{ fontSize: '6rem' }} />
          </IconButton>
          <Typography variant="body2" sx={{ fontSize: '1.1rem' }}>{phoneNumber}</Typography>
          <br />
          <br />
          <br />
        </Grid>
      </Grid>
    </Box>
    </Container>
  );
};

export default AffiliateContactUs;
