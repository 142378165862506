import React, { useState, useEffect } from 'react';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { DataGrid } from "@mui/x-data-grid";
import FormTextField from '../components/form/FormTextField';
import FormSelect from "../components/form/FormSelect";
import { useDispatch } from "react-redux";
import { setAlert } from "../features/slices/alertSlice";
import '../components/Affiliate/reportstyles.css'
import * as Constant from "../features/constant";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import AffiliateBankTables from '../components/Affiliate/AffiliateBankTables';
import AffiliateWallet from '../components/Affiliate/AffiliateWallet';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField, Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box
} from '@mui/material';
import AffiliateSideNav from '../components/Affiliate/AffiliateSideNav';
import { useGetProfileQuery, useGetBankAccountsQuery, usePlayerBanksMutation } from "../features/api/userApiSlice";

const AffiliateBankDetails = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [selectedBank, setSelectedBank] = useState('');
  const [banks, setBanks] = useState([]);
  const { t } = useTranslation();

  const columns = ['Bank', 'Bank Account', 'Account No'];
  const data2 = [
    { Bank: 'Maybank', 'Bank Account': 'Savings', 'Account No': '123456789', deletable: true },
    { Bank: 'CIMB Bank', 'Bank Account': 'Savings', 'Account No': '987654321', deletable: true },
  ];

  //Open close dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBankChange = (event) => {
    setSelectedBank(event.target.value); // Update selected bank
  };

  const {
    data: user,
    isFetching: isUserFetching,
    isLoading: isUserLoading,
  } = useGetProfileQuery({ prefetch: true });

  const {
    data: bankDetails,
    isLoading: isBankDetailsLoading,
    isError: isBankDetailsError,
    isSuccess: isBankDetailsSuccess,
    error: bankDetailsError,
  } = useGetBankAccountsQuery();
  

  const bankSelection = useState(t("banks"));
  const [email, setEmail] = useState(user?.data?.email);
  const [mobileNo, setMobileNo] = useState(user?.data?.mobileNumber);
  const [emailError, setEmailError] = useState(false);


  const bankSchema = yup.object().shape({
    accountname: yup.string().required(t("validation.bankaccname")),
    bankcode: yup.string().required(t("validation.bankaccname")),
    bankaccountnumber: yup.string().required(t("validation.bankaccno"))
  });


  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(bankSchema),
  });

  const [playerBanks, { data, isLoading, isError, isSuccess, error }] =
    usePlayerBanksMutation();

  const submitBank = async (data) => {

    let newData = {
      bankCode: data?.bankcode,
      bankAccountNumber: data?.bankaccountnumber,
      bankAccountName: data?.accountname,
    };

    await playerBanks(newData).unwrap();
    window.location.reload();
  };

  useEffect(() => {
    if (!isLoading && isSuccess) {
      dispatch(
        setAlert({
          isOpen: true,
          message: t("walletcard.bankaccountpage.successmessage"),
          variant: "success",
        })
      );
      reset();
    }
    if (isError) {
      dispatch(
        setAlert({
          isOpen: true,
          message: error.data.message,
          variant: "error",
        })
      );
    }
  }, [isLoading, isSuccess, isError]);



  // useEffect(() => {
  //   setBanks(bankDetails?.result?.bankConfigs[0]);
  // }, []);

  useEffect(() => {
    if (user) {
      setEmail(user.data.email);
      setMobileNo(user.data.mobileNumber);
    }
  }, [user]);


  return (

    <Box className='container' pt={{ xs: 7, sm: 0, lg: 0, xl: 0 }}>
      <AffiliateSideNav />
      <div className='menuWrapper'>
        {!mobileView && (
          <AffiliateWallet />
        )}
        <div className='datePickerContainer'>
          <h3 style={{ flexGrow: 1 }}>{t("affiliatebankdetails.bankdetails")}</h3>
          <Button variant="contained" onClick={handleClickOpen} style={{ marginBottom: '10px', display: 'flex', justifyContent: 'flex-end' }}>{t("affiliatebankdetails.addbank")}</Button>
        </div>
        <div className='report-container'>
          {/* <AffiliateBankTables data={data2} columns={columns} /> */}
          <Box sx={{ height: 400, width: "100%", mt: "15px" }}>
            <DataGrid
              sx={{
                '.MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '&.MuiDataGrid-root': {
                  border: 'none',
                },
                "& .MuiDialogContent-root": {
                  overflowY: "auto",
                  "::-webkit-scrollbar": {
                    width: "12px",
                  },
                  "::-webkit-scrollbar-track": {
                    background: theme.palette.text.disabled,
                  },
                  "::-webkit-scrollbar-thumb": {
                    background: theme.palette.background.paper,
                  },
                },
                color: "white",
                backgroundColor: theme.palette.primary.main,
              }}
              rows={
                bankDetails?.result?.data.map((content, idx) => {
                  return {
                    ...content,
                    id: idx + 1,
                  };
                }) || []

              }
              columns={[
                {
                  field: "id",
                  headerName: t("walletcard.transactionpage.id"),
                  width: 150,
                  editable: false,
                },
                {
                  field: "bankAccountName",
                  headerName: t("walletcard.bankaccountpage.accountname"),
                  width: 150,
                  editable: false,
                },
                {
                  field: "bankAccountNumber",
                  headerName: t("walletcard.bankaccountpage.bankaccount"),
                  width: 150,
                  editable: false,
                },
                {
                  field: "bankCode",
                  headerName: t("walletcard.bankaccountpage.bankname"),
                  width: 150,
                  editable: false,
                }
              ]}
              hideFooter={true}
              rowsPerPageOptions={[5]}
            />
          </Box>
        </div>
      </div>
      {/* Dialog for add new bank */}
      <Dialog open={open} onClose={handleClose}
        PaperProps={{
          style: { width: '600px', maxWidth: '90%' },
        }}>
        <DialogTitle style={{ fontSize: '20px', fontWeight: 'bold', padding: '16px 24px', backgroundColor: '#313338', color: 'white' }}>Add Bank Account</DialogTitle>
        <DialogContent style={{ padding: '20px', backgroundColor: '#313338' }}>
          <Box display="flex" flexDirection="column" >
            <form onSubmit={handleSubmit(submitBank)}>
              <FormTextField
                label={t("walletcard.bankaccountpage.accountname")}
                control={control}
                controllerName="accountname"
                defaultValue={user?.result?.fullname}
                fullWidth
                disabled={true}
              />
              <FormSelect
                label={t("walletcard.bankaccountpage.bankname")}
                control={control}
                controllerName="bankcode"
                items={bankDetails?.result?.bankConfigs.map((item) => item.bankCode)}
                fullWidth
              />
              <FormTextField
                label={t("walletcard.bankaccountpage.bankaccount")}
                control={control}
                controllerName="bankaccountnumber"
                defaultValue={""}
                fullWidth
              />
              <Box alignSelf="center" display={'flex'} gap={2} mb="15px" mt="15px">
                <Button variant="contained" type="submit" style={{ backgroundColor: '#f39c12', color: '#fff', padding: '10px 20px', fontSize: '16px', fontWeight: 'bold', borderRadius: '8px' }}>
                  {t("walletcard.submit")}
                </Button>
                <Button onClick={handleClose} color="primary" variant="contained" style={{ backgroundColor: '#f39c12', color: '#fff', padding: '10px 20px', fontSize: '16px', fontWeight: 'bold', borderRadius: '8px' }}>
                {t("affiliatebankdetails.cancel")}
                </Button>
              </Box>

            </form>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>


  );
};

export default AffiliateBankDetails;
