import React from 'react';
import { useNavigate } from "react-router-dom";
import * as Constant from "../../features/constant";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { RiExchangeFundsFill } from "react-icons/ri";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { AttachMoney, LockReset, Person, AccountBalance } from "@mui/icons-material";
import { BiMoneyWithdraw } from "react-icons/bi";
import { TbReportSearch } from "react-icons/tb";
import { CgProfile } from "react-icons/cg";
import { useTranslation } from "react-i18next";

const AffiliateSideNav = () => {
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        !mobileView ? (
            <Box className='sidebar'>
                <div className='menuWrapper'>
                    <div className='sidebar-card'>
                        <RiExchangeFundsFill size={30} />
                        {t("sidenav.funds")}
                    </div>
                    {/* <div className='menuItem' onClick={() => navigate(Constant.getPath('/affiliatetransfer'))}>
                        <FaMoneyBillTransfer size={30} />
                        {t("sidenav.transfer")}
                    </div>
                    <div className='menuItem' onClick={() => navigate(Constant.getPath('/affiliatedeposit'))} >
                        <AttachMoney sx={{ height: 30, width: 30 }} />
                        {t("sidenav.deposit")}
                    </div> */}
                    <div className='menuItem' onClick={() => navigate(Constant.getPath('/affiliatereport'))}>
                        <TbReportSearch size={30} />
                        {t("sidenav.report")}
                    </div>
                    <div className='menuItem' onClick={() => navigate(Constant.getPath('/affiliatewithdraw'))}>
                        <BiMoneyWithdraw size={30} />
                        {t("sidenav.withdraw")}
                    </div>
                    <div className='sidebar-card'>
                        <Person sx={{ height: 30, width: 30 }} />
                        {t("sidenav.account")}
                    </div>
                    <div className='menuItem' onClick={() => navigate(Constant.getPath('/affiliateprofile'))}>
                        <CgProfile size={30} />
                        {t("sidenav.myprofile")}
                    </div>
                    <div className='menuItem' onClick={() => navigate(Constant.getPath('/affiliatechangepassword'))}>
                        <LockReset sx={{ height: 30, width: 30 }} />
                        {t("sidenav.changepassword")}
                    </div>
                    <div className='menuItem' onClick={() => navigate(Constant.getPath('/affiliatebankdetails'))}>
                        <AccountBalance sx={{ height: 30, width: 30 }} />
                        {t("sidenav.bankdetails")}
                    </div>
                </div>
            </Box>
        ) : undefined
    );
};

export default AffiliateSideNav;