import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Logout, AccountCircle, ArrowBackIos } from "@mui/icons-material";
import {
  AppBar,
  Toolbar,
  Grid,
  Box,
  useTheme,
  Button,
  Typography,
  colors,
} from "@mui/material";
import i18n from "i18next";
import { selectCurrentToken } from "../../features/slices/authSlice";
import { logout } from "../../features/slices/authSlice";
import * as Constant from "../../features/constant";
import { useGetProfileQuery } from "../../features/api/userApiSlice";

import Image from "../common/Image";
import DropdownMenu from "../common/DropdownMenu";
import Backdrop from "../common/Backdrop";

import { useTranslation } from "react-i18next";
import LanguageDropdown from "../common/LanguageDropdown";
import { useGetLanguagesQuery } from "../../features/api/publicApiSlice";
import AffiliateHeaderTitle from "../Affiliate/AffiliateHeaderTitle";


const MobileHeader = ({ background }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(selectCurrentToken);
  const domain = window.location.origin;
  const apkUrl = domain.replace("/\\/(en|bn)-bd\\//, \"/\"", "/") + "/bagh8.apk";
  const [showPopup, setShowPopup] = useState(true);
  const location = useLocation();
  const logoutProfileItems = [
    {
      label: i18n.t("walletcard.logout"),
      icon: <Logout sx={{ height: 20 }} />,
    },
  ];
  const handleClose = () => {
    setShowPopup(false);
  };
  const handleButtonClick = () => {
    window.open(apkUrl, "_blank");
  };
  const {
    data: languages,
    isFetching: isLanguagesFetching,
    isLoading: isLanguagesLoading,
    isSuccess: isLanguagesSuccess,
  } = useGetLanguagesQuery({ prefetch: true });

  const {
    data: user,
    isFetching: isUserFetching,
    isLoading: isUserLoading,
  } = useGetProfileQuery({ prefetch: true });

  if (isUserFetching || isUserLoading) {
    return <Backdrop />;
  }

  const handleLogoutOnClick = (item) => {
    if (item?.label === i18n.t("walletcard.logout")) {
      dispatch(logout());
      navigate(Constant.getPath("/"));
    }
  };

  return (
    <>
      <AppBar position="fixed" sx={{ marginBottom: '10px' }}>
        <Toolbar
          sx={{
            background: `#121415`,
            height: 0,
            justifyItems: "center"
          }}
        >

          {(location.pathname === Constant.getPath("/affiliatecontactus") || location.pathname === Constant.getPath("/affiliatecommissionplan") ||
            location.pathname === Constant.getPath("/affiliateterms") || location.pathname === Constant.getPath("/affiliatefaq") ||
            location.pathname === Constant.getPath("/affiliatetransfer") || location.pathname === Constant.getPath("/affiliatedeposit") ||
            location.pathname === Constant.getPath("/affiliatewithdraw") || location.pathname === Constant.getPath("/affiliatereport") ||
            location.pathname === Constant.getPath("/affiliateoverview") || location.pathname === Constant.getPath("/affiliateprofile") ||
            location.pathname === Constant.getPath("/affiliatechangepassword") || location.pathname === Constant.getPath("/affiliatebankdetails") ||
            location.pathname === Constant.getPath("/affiliatetransfermenu")
          ) ? (
            console.log(location.pathname),
            <Box width={"100%"} position={"relative"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
              {/* <Button sx={{ position: "absolute", left: 1 }} onClick={() => { navigate(-1) }}>
                <ArrowBackIos />
              </Button> */}

              <AffiliateHeaderTitle />
            </Box>
          ) : (
            <Grid container justifyContent="space-between" >
              <Grid item xs={3} textAlign="left" sx={{ maxWidth: "25%" }}>
                <Box sx={{ display: "flex", alignItems: "center", height: "100%", }}>
                  <Image
                    src={process.env.REACT_APP_LOGO}
                    onClick={() => navigate(Constant.getPath("/"))}
                    alt={Constant.ALT_TAG_LOGO}
                  />
                </Box>
              </Grid>
              {token && (
                <Grid item xs={4} alignSelf="right" textAlign="right" sx={{ marginRight: "10px" }}>
                  <DropdownMenu
                    sxMenu={{
                      mt: "40px",
                    }}
                    items={logoutProfileItems}
                    handleOnClickEvent={handleLogoutOnClick}
                    icon={true}
                  >
                    <Typography sx={{
                      color: "white",
                      float: "right",
                      marginTop: "8px",
                      marginRight: "10px",
                      fontSize: "14px",
                    }}>
                      {process.env.REACT_APP_CURRENCY_CODE}{user?.result?.currentCredit}
                    </Typography>

                    <AccountCircle />
                  </DropdownMenu>
                </Grid>
              )}
              {/* Mobile view login & register (below banner) */}
              {!token && (
                <Grid item xs={7} textAlign="right" sx={{ maxWidth: "75%" }}>
                  <Box sx={{ display: "flex" }}>
                    <Button
                      variant="contained"
                      sx={{
                        //background:  theme.palette.button.secondary.main,
                        backgroundImage: 'url(/assets/img/yellow_big.png)',
                        width: "40%", // Adjust the width as needed and consider the margin
                        marginRight: "8px", // Add margin to create a gap
                        borderRadius: '3px', // Add border-radius for curved corners
                        padding: 0,
                        fontSize: "12px",
                        backgroundSize: "100% 100%",

                      }}
                      disableRipple
                      onClick={() =>
                        navigate(Constant.getPath("/login"))
                      }
                    >
                      {t("home.login")}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        width: "40%",
                        borderRadius: '3px', // Add border-radius for curved corners
                        background: theme.palette.button.primary.main,
                        backgroundImage: 'url(/assets/img/red_big.png)',
                        padding: 0,
                        fontSize: "12px",
                        backgroundSize: "100% 100%",
                        transition: 'box-shadow 0.4s ease-in-out',
                        '&:hover': {
                          boxShadow: '0 0 20px rgba(238, 40, 66, 0.7)', // Adjust hover shadow color and size as needed
                        },
                        '@keyframes shadow-pulse': {
                          '0%': {
                            boxShadow: '0 0 0 5px rgba(238, 40, 66, 0.7)', // Adjust initial shadow color and size as needed
                          },
                          '80%': {
                            boxShadow: '0 0 5px rgba(238, 40, 66, 0.7)', // Adjust pulse shadow color and size as needed
                          },
                        },
                        '@keyframes vibration': {
                          '0%': {
                            transform: 'translateX(0)',
                          },
                          '25%': {
                            transform: 'translateX(-2px)',
                          },
                          '50%': {
                            transform: 'translateX(2px)',
                          },
                          '75%': {
                            transform: 'translateX(-2px)',
                          },
                          '100%': {
                            transform: 'translateX(0)',
                          },
                        },
                        animation: 'shadow-pulse 1s infinite alternate, vibration 0.7s infinite', // Combine pulse and vibration animations

                      }}
                      disableRipple
                      onClick={() =>
                        navigate(Constant.getPath("/register"))
                      }
                    >
                      {t("home.signup")}
                    </Button>
                    <LanguageDropdown languages={languages?.result} />

                  </Box>
                </Grid>
              )}
            </Grid>
          )}
        </Toolbar>
      </AppBar >
    </>
  );
};

MobileHeader.propTypes = {
  background: PropTypes.string,
};

MobileHeader.defaultProps = {
  background: "",
};

export default MobileHeader;
