import React from "react";
import { Container, Box, useTheme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "../utils/en.json";

const AffiliateFaq = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Container sx={{ color: "white" }}>
  <Box my={4} pt={{ xs: 7, sm: 0, lg: 0, xl: 0 }}>
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
          sx={{ fontSize: '1.5rem' }}
        >
          {t("faqs.title")}
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
          sx={{ fontSize: '1.2rem' }}
        >
          {t("faqs.para1.title")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1.1rem' }}>
          {t("faqs.para1.desc")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1.1rem' }}>
          {t("faqs.para1.desc2")}
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
          sx={{ fontSize: '1.2rem' }}
        >
          {t("faqs.para2.title")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1.1rem' }}>
          {t("faqs.para2.desc")}
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
          sx={{ fontSize: '1.2rem' }}
        >
          {t("faqs.para3.title")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1.1rem' }}>
          {t("faqs.para3.desc")}
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
          sx={{ fontSize: '1.2rem' }}
        >
          {t("faqs.para4.title")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1.1rem' }}>
          {t("faqs.para4.desc")}
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
          sx={{ fontSize: '1.2rem' }}
        >
          {t("faqs.para5.title")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1.1rem' }}>
          {t("faqs.para5.desc")}
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
          sx={{ fontSize: '1.2rem' }}
        >
          {t("faqs.para6.title")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1.1rem' }}>
          {t("faqs.para6.desc")}
        </Typography>
        <br />
      </Box>
    </Container>
  );
};

export default AffiliateFaq;
