import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getPath } from "../../features/constant";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AffiliateHeaderTitle = () => {
    const [title, setTitle] = useState('');
    const location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        switch (location.pathname) {
            case getPath("/register"):
                setTitle(t("header.signup"));
                break;

            case getPath("/login"):
                setTitle(t("header.login"));
                break;

            case getPath("/affiliatecontactus"):
                setTitle(t("header.contactusheader"));
                break;

            case getPath("/affiliatecommissionplan"):
                setTitle(t("header.commissionplanheader"));
                break;

            case getPath("/affiliateterms"):
                setTitle(t("header.termsconditionheader"));
                break;

            case getPath("/affiliatefaq"):
                setTitle(t("header.faqheader"));
                break;

            case getPath("/affiliatetransfermenu"):
                setTitle(t("header.menu"));
                break;

            case getPath("/affiliateoverview"):
                setTitle(t("header.overview"));
                break;

            case getPath("/affiliatereport"):
                setTitle(t("header.report"));
                break;

            case getPath("/affiliatetransfer"):
                setTitle(t("header.transfer"));
                break;

            case getPath("/affiliatedeposit"):
                setTitle(t("header.deposit"));
                break;

            case getPath("/affiliatewithdraw"):
                setTitle(t("header.withdrawal"));
                break;

            case getPath("/affiliateprofile"):
                setTitle(t("header.myprofile"));
                break;

            case getPath("/affiliatechangepassword"):
                setTitle(t("header.changepassword"));
                break;

            case getPath("/affiliatebankdetails"):
                setTitle(t("header.bankdetails"));
                break;

            default:
                setTitle("");
        }

    }, [location.pathname]);

    return <Typography sx={{ color: "white", alignSelf: "center", fontSize: 20, fontWeight: 'bold' }}>
        {title}
    </Typography>
}

export default AffiliateHeaderTitle;