import React from 'react';
import { useState,useEffect } from 'react';
import { useDispatch } from "react-redux";
import { setAlert } from '../features/slices/alertSlice';
import { Controller } from "react-hook-form";
import * as yup from "yup";
import '../components/Affiliate/reportstyles.css'
import { useNavigate } from 'react-router-dom';
import { Form, useForm } from "react-hook-form";
import AffiliateSideNav from '../components/Affiliate/AffiliateSideNav';
import AffiliateWallet from '../components/Affiliate/AffiliateWallet';
import { useTranslation } from "react-i18next";
import FormTextField from '../components/form/FormTextField';
import FormSelect from "../components/form/FormSelect";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGetProfileQuery, useGetBankAccountsQuery, usePlayerBanksMutation } from "../features/api/userApiSlice";
import * as Constant from "../features/constant";
import {
  useGetDepositDetailsQuery,
  useDepositMutation,
  useGetWithdrawDetailsQuery,
  useWithdrawMutation,
  useGetHistoryMutation,
} from "../features/api/transactionApiSlice";

import {
  Box,
  Grid,
  TextField,
  Button,
  DialogContent,
  Dialog,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  DialogTitle, 
  MenuItem,
  Select,
  InputLabel,
  useMediaQuery,
  useTheme,
} from '@mui/material';


const AffiliateWithdraw = () => {

  const navigate = useNavigate();
  const theme = useTheme();

  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState('deposit');
  const [selectedBank, setSelectedBank] = useState("");
  const [bankAccountName, setBankAccountName] = useState('');
  const [bankAccountNumber, setBankAccountNumber] = useState('');
  const [bankCode, setBankCode] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const {
    data: bankDetails,
    isLoading: isBankDetailsLoading,
    isError: isBankDetailsError,
    isSuccess: isBankDetailsSuccess,
    error: bankDetailsError,
  } = useGetBankAccountsQuery();

  const {
    data: withdrawDetails,
    isLoading: isWithdrawDetailsLoading,
    isError: isWithdrawDetailsError,
    isSuccess: isWithdrawDetailsSuccess,
    error: withdrawDetailsError,
  } = useGetWithdrawDetailsQuery();

  const [withdraw, { data, isLoading, isError, isSuccess, error }] =
  useWithdrawMutation();

  const withdrawalSchema = yup.object().shape({
    bankCode: yup.string().required(t("validation.bankname")),
    bankAccountName: yup.string().required(t("validation.bankaccname")),
    bankAccountNumber: yup.string().required(t("validation.bankaccno")),
    amount: yup
      .number()
      .typeError(t("validation.amountonly"))
      .min(700, "Min 700.00")
      .required(t("validation.amountisrequired")),
  });
  
  const onSubmit = (data) => {
    // Handle form submission, like API call, etc.
    submitWithdrawal(data);
    //console.log("Form Data:", data);

   
    reset();
  };

  const submitWithdrawal = async (data) => {

  // console.log(withdrawAmount);
  //  console.log(bankAccountName);
  // console.log(bankAccountNumber);
  // console.log(selectedBank);
  //console.log(bankCode);
  let newData = {
    amount: data.amount,
    bankAccountName: bankAccountName,
    bankAccountNumber: bankAccountNumber,
    bankCode: bankCode,
  };
   // console.log(newData);

    try {
  
      await withdraw(newData).unwrap();
      dispatch(
        setAlert({
          isOpen: true,
          message: t("withdrawpage.successwithdraw"), 
          variant: "success",
        })
      );

      navigate(Constant.getPath('/'));   

    } catch (error) {
      console.error("API error:", error);
      
      // reset({
      //   bankCode: "", 
      //   bankAccountName: data.bankAccountName, 
      //   bankAccountNo: "", 
      //   amount: "", 
      // });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (bankDetails) => {
  //  console.log(bankDetails?.bankCode);
  //  console.log(bankDetails?.bankAccountNumber);
  //  console.log(bankDetails?.bankAccountName);
    
    setBankAccountName(bankDetails?.bankAccountName);
    setBankCode(bankDetails?.bankCode);
    setBankAccountNumber(bankDetails?.bankAccountNumber);
    reset({
      bankCode: bankDetails?.bankCode,
      bankAccountName: bankDetails?.bankAccountName,
      bankAccountNumber: bankDetails?.bankAccountNumber,
    });
    setOpen(false);
  };


  const handleClick = (value) => {
    setSelectedValue(value);
  };

  const {
    data: user,
    isFetching: isUserFetching,
    isLoading: isUserLoading,
  } = useGetProfileQuery({ prefetch: true });

  const [email, setEmail] = useState(user?.data?.email);
  const [mobileNo, setMobileNo] = useState(user?.data?.mobileNumber);


  const { control, handleSubmit, reset, getValues,formState: { errors } } = useForm({
    defaultValues: {
    //  bankAccountName: bankAccountName ,
     // bankCode: bankCode, 
    },
    resolver: yupResolver(withdrawalSchema),
  });
 // console.log(errors);
  useEffect(() => {
    if (user) {
      setEmail(user.data.email);
      setMobileNo(user.data.mobileNumber);
    }
    if (isBankDetailsSuccess && bankDetails?.result?.data[0]?.bankAccountName) {
      setBankAccountName(bankDetails.result.data[0].bankAccountName);
     // setBankCode(bankDetails.result.data[0].bankCode);
    //  setBankAccountNumber(bankDetails.result.data[0].bankAccountNumber);
    }
    if (bankAccountName) {
      reset({ bankAccountName });
    }

    if (!isLoading && isError) {
      let errorMessage = "An unknown error occurred"; // Default message
  
      // Check the error response for specific cases
      if (error?.data) {
        if (error.data.message === "game.withdrawal.pending.record") {
          errorMessage = "Ongoing request pending. Please try again later.";
        } else if (error.data.message === "game.withdrawal.insufficient.credit") {
          errorMessage = "Insufficient credit. Please enter valid amount.";
        } else if (error.data.message === "game.withdrawal.max.amount") {
          errorMessage = "Withdraw amount is greater than allowed withdraw amount.";
        } else if (error.data.message === "game.withdrawal.min.amount") {
          errorMessage = "Withdraw amount is less than allowed withdraw amount.";
        } else if (error.data.message === "game.withdrawal.no.meet") {
          errorMessage = "User didn't meet turnover/rollover requirement."; 
        } else if (error.data.message) {
          errorMessage = error.data.message; // Fallback to the provided error message
        }
      }
  
      dispatch(
        setAlert({
          isOpen: true,
          message: errorMessage,
          variant: "error",
        })
      );
      reset();
    }

  }, [user,isBankDetailsSuccess, bankDetails,bankAccountName,reset,isLoading, isSuccess, isError]);
  // const { control, handleSubmit, reset } = useForm({
  //   resolver: yupResolver(passwordSchema),
  // });

  // const handleWalletChange = (event, newWallet) => {
  //   if (newWallet !== null) {
  //     setWallet(newWallet);
  //   }
  // }

 // console.log(withdrawDetails);
 // console.log(bankAccountName);
 // console.log(selectedBank);
 //console.log(bankCode);

 function SimpleDialog(props) {
  const { onClose, bankAccountName, open } = props;

  const handleClose = () => {
    onClose(bankAccountName);
  };

  const handleListItemClick = (bankDetails) => {
    onClose(bankDetails);
  };
  return (
      <Dialog onClose={handleClose} open={open} sx={{"& .MuiPaper-root": {
          bgcolor: theme.palette.background.paper,
          width: "100%",
          margin:0,
          maxHeight: "100%",
        }}}>
        <DialogTitle sx={{color: 'white'}} >{t("withdrawpage.selectbank")}</DialogTitle>
        <List sx={{ pt: 0 }}>
          {bankDetails?.result?.data?.map((data) => (
              <ListItem disableGutters key={data?.bankCode}>
                <ListItemButton onClick={() => handleListItemClick(data)} sx={{
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  padding: '10px'
                }}>
                  <div sx={{ display: 'flex', flexDirection: 'column' }}>
                    <ListItemText sx={{color: 'white'}} primary={data?.bankAccountNumber}/>
                    <ListItemText sx={{color: 'white'}} primary={data?.bankAccountName}/>
                    <ListItemText sx={{color: 'white'}} primary={data?.bankCode}  />
                  </div>
                </ListItemButton>
              </ListItem>
          ))}
        </List>
      </Dialog>
  );
}


  return (
    <>
      <Box className='container' pt={{ xs: 7, sm: 0, lg: 0, xl: 0 }}>
        <AffiliateSideNav />
        <div className='menuWrapper'>
          <AffiliateWallet />
          <div className='report-container'>
            <Box sx={{
              textAlign: 'center',
              color: '#fff',
              maxWidth: '100%',
              backgroundColor: '#333',
              borderRadius: '4px',
              padding: "1px 0"
            }}>
              <h2>{t("withdrawpage.banktransfer")}</h2>
            </Box>
            <form onSubmit={handleSubmit(submitWithdrawal)}>
            <Grid container spacing={2} paddingTop={4}>
              {/* <Grid item xs={12}>
                <InputLabel sx={{ color: 'white' }}>{t("withdrawpage.withdrawoptions")}</InputLabel>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Button
                    sx={{
                      margin: '8px',
                      border: '1px solid #FDB813',
                      padding: '10px 20px',
                      backgroundColor: selectedValue === 'deposit' ? '#FDB813' : '#313338',
                      fontWeight: selectedValue === 'deposit' ? 'bold' : undefined,
                      "&:hover": {
                        backgroundColor: selectedValue === 'deposit' ? '#FDB813' : '#313338'
                      }
                    }}
                    onClick={() => handleClick('deposit')}
                  >
                    {t("withdrawpage.depositwallet")}
                  </Button>
                  <Button
                    sx={{
                      margin: '8px',
                      border: '1px solid #FDB813',
                      padding: '10px 20px',
                      backgroundColor: selectedValue === 'commission' ? '#FDB813' : '#313338',
                      fontWeight: selectedValue === 'commission' ? 'bold' : undefined,
                      "&:hover": {
                        backgroundColor: selectedValue === 'commission' ? '#FDB813' : '#313338'
                      }
                    }}
                    onClick={() => handleClick('commission')}
                  >
                    {t("withdrawpage.commissionwallet")}
                  </Button>
                </Box>
              </Grid> */}
               
                <SimpleDialog
                  bankaccountname={bankAccountName}
                  open={open}
                  onClose={handleClose}
                />
             
              <Grid item xs={12} >
              
                <InputLabel id="bank-label" sx={{ color: 'white' }}>{t("withdrawpage.banklabel")}</InputLabel>

                        <Button
                          onClick={() => setOpen(true)}
                          variant="outlined"
                          sx={{
                            color: 'white',
                            width: '60%',
                            textAlign: 'left',
                            borderColor: 'white',
                            ':hover': { borderColor: 'white' },
                          }}
                        >
                          {selectedBank ? selectedBank : t("withdrawpage.defaultselection")}
                        </Button>
              </Grid>

              <Grid item xs={12}>
              <InputLabel id="bank-label" sx={{ color: 'white' }}>{t("withdrawpage.bankcode")}</InputLabel>
                <FormTextField
                  fullWidth
                  control={control}
                  controllerName="bankCode"
                //  label={t("withdrawpage.accountname")}
                  variant="outlined"
                  dynamicValue= {bankCode}
                  defaultValue={bankCode}
                  disabled
                  onChange={(e) => setBankCode(e.target.value)}  
                  InputLabelProps={{
                    style: { color: 'white' },
                  }}
                  sx={{
                    // maxWidth: {
                    //   xs: "100%",
                    //   md: "60%"
                    // },
                    width: '60%',
                    input: {
                      color: 'white',
                    }
                  }}

                />
              </Grid>

              <Grid item xs={12}>
              <InputLabel id="bank-label" sx={{ color: 'white' }}>{t("withdrawpage.accountname")}</InputLabel>
                <FormTextField
                  fullWidth
                  control={control}
                  controllerName="bankAccountName"
                //  label={t("withdrawpage.accountname")}
                  variant="outlined"
                  dynamicValue= {bankAccountName}
                  defaultValue={bankAccountName}
                  disabled
                  InputLabelProps={{
                    style: { color: 'white' },
                  }}
                  sx={{
                    // maxWidth: {
                    //   xs: "100%",
                    //   md: "60%"
                    // },
                    width: '60%',
                    input: {
                      color: 'white',
                    }
                  }}

                />
              </Grid>

              <Grid item xs={12}>
              <InputLabel id="bank-label" sx={{ color: 'white' }}>{t("withdrawpage.accountnumber")}</InputLabel>
                <FormTextField
                  fullWidth
                  control={control}
                  controllerName="bankAccountNo"
                //  label={t("withdrawpage.accountnumber")}
                  variant="outlined"
                  disabled={true}
                  dynamicValue= {bankAccountNumber}
                  defaultValue={bankAccountNumber} 
                  onChange={(e) => setBankAccountNumber(e.target.value)}  

                  sx={{
                    maxWidth: {
                      xs: "100%",
                      md: "60%"
                    },
                    input: {
                      color: "white",
                    }
                  }}
                  InputLabelProps={{
                    style: { color: 'white' },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
              <InputLabel id="bank-label" sx={{ color: 'white' }}>{t("withdrawpage.amount")}</InputLabel>
                <FormTextField
                  fullWidth
                  control={control}
                  controllerName="amount"
                //  label={t("withdrawpage.amount")}
                  variant="outlined"
                  placeholder={withdrawDetails?.withdrawalDetails?.minimum}
                  value={withdrawAmount}  
                  onChange={(e) => setWithdrawAmount(e.target.value)}  

                  sx={{
                    maxWidth: {
                      xs: "100%",
                      md: "60%"
                    },
                    input: {
                      color: 'white',
                    }
                  }}
                  InputLabelProps={{
                    style: { color: 'white' },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"                
                  variant="contained"
                  sx={{
                    backgroundColor: '#EE2842',
                    color: '#fff',
                    fontWeight: "bold",
                    padding: '10px 20px',
                    marginTop: '10px',
                    width: {
                      xs: '100%',
                      sm: 'auto'
                    },
                    textAlign: {
                      xs: 'center',
                      sm: 'start'
                    }
                  }}
                >
                  {t("withdrawpage.withdraw")}
                </Button>
              </Grid>          
            </Grid>
            </form> 
          </div>
        </div>
      </Box>
    </>
  );


};

export default AffiliateWithdraw;
