import { apiSlice } from "./apiSlice";

export const userApiSlice = apiSlice.injectEndpoints({
  tagTypes: [
    "Profile",
    "UpdateProfile",
    "RankInfo",
    "ShareLink",
    "Affiliate",
    "CurrentCredit",
    "BankAccounts",
    "PlayerBanks",
    "PlayerGetOtp",
    "PlayerVerifyOtp",
    "VerifyIdentity"
  ],
  endpoints: (builder) => ({
    listAffiliate: builder.mutation({
      query: (body) => ({
        url: "/user/api/v1/listAffiliate",
        method: "POST",
        body,
      }),
      providesTags: ["Affiliate"],
    }),
    getProfile: builder.query({
      query: () => "/user/api/v1/profile",
      providesTags: ["Profile"],
    }),
    updateProfile: builder.mutation({
      query: (body) => ({
        url: "/user/api/v1/profile",
        method: "PUT",
        body,
      }),
      providesTags: ["UpdateProfile"],
      invalidatesTag: ["Profile"],
    }),
    getRankInfo: builder.query({
      query: () => "/user/api/v1/rankInfo",
      providesTags: ["RankInfo"],
    }),
    getShareLink: builder.query({
      query: () => "/user/api/v1/shareLink",
      providesTags: ["ShareLink"],
    }),
    getBankAccounts: builder.query({
      query: () => "/user/api/v1/listBankAccounts",
      providesTags: ["BankAccounts"],
    }),
    playerBanks: builder.mutation({
      query: (playerBank) => ({
        url: `/user/api/v1/addBankAccount`,
        method: "POST",
        body: playerBank,
      }),
      providesTags: ["PlayerBanks"],
    }),
    getDownline: builder.query({

      query: ({ startdate, enddate }) => {
        let queryString = ""

        if (startdate) {
          const formattedStartDate = startdate.replace(/-/g, "/");
          queryString += `startDate=${formattedStartDate}`;
        }
        if (enddate) {
          const formattedEndDate = enddate.replace(/-/g, "/");
          queryString += (queryString ? "&" : "") + `endDate=${formattedEndDate}`;
        } 
        return {
          url: `/user/api/v1/getdownlineusinguser?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["DownlinePlayers"],

    }),
    getDownlineFirstDepositDetails: builder.query({

      query: ({ startdate, enddate }) => {
        let queryString = ""

        if (startdate) {
          const formattedStartDate = startdate.replace(/-/g, "/");
          queryString += `startDate=${formattedStartDate}`;
        }
        if (enddate) {
          const formattedEndDate = enddate.replace(/-/g, "/");
          queryString += (queryString ? "&" : "") + `endDate=${formattedEndDate}`;
        }  
        return {
          url: `/user/api/v1/getfirsttimedepositdetails?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["DownlineFirstDeposit"],

    }),
    getDownlineDepositHistory: builder.query({

      query: ({ playerProfileId }) => {
        let queryString = `playerProfileId=${playerProfileId}`;

        return {
          url: `/user/api/v1/getdownlinedeposithistorydetails?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["DownlineDepositHistory"],

    }),
    getDownlineBetHistory: builder.query({

      query: ({ playerProfileId,startdate, enddate }) => {
        let queryString = `playerProfileId=${playerProfileId}&`;

        if (startdate) {
          const formattedStartDate = startdate.replace(/-/g, "/");
          queryString += `startDate=${formattedStartDate}`;
        }
        if (enddate) {
          const formattedEndDate = enddate.replace(/-/g, "/");
          queryString += (queryString ? "&" : "") + `endDate=${formattedEndDate}`;
        }  
        return {
          url: `/user/api/v1/getdownlinebethistorydetails?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["DownlineBetHistory"],

    }),
    playerGetOtp: builder.query({
      query: (channel) => ({
        url: `/user/api/v1/getOtp?channel=${channel}`,
      }),
      providesTags: ["GetOtp"],
    }),
    playerVerifyOtp: builder.query({
      query: ({ channel, otp }) => ({
        url: `/user/api/v1/verifyOtp?channel=${channel}&otp=${otp}`,
      }),
      providesTags: ["VerifyOtp"],
    }),
    verifyIdentity: builder.mutation({
      query: ({ identity }) => ({
        url: `/user/api/v1/verifyIdentity`,
        method: "POST",
        body: identity,
      }),
      providesTags: ["VerifyIdentity"],
    }),
  }),
});

export const {
  useGetProfileQuery,
  useUpdateProfileMutation,
  useGetRankInfoQuery,
  useGetShareLinkQuery,
  useGetBankAccountsQuery,
  usePlayerBanksMutation,
  useLazyPlayerGetOtpQuery,
  useLazyPlayerVerifyOtpQuery,
  useVerifyIdentityMutation,
  useGetDownlineQuery,
  useGetDownlineFirstDepositDetailsQuery,
  useGetDownlineDepositHistoryQuery,
  useGetDownlineBetHistoryQuery
} = userApiSlice;
