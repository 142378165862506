import React from "react";
import { Container, Box, useTheme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import "../utils/en.json";

const AffiliateCommissionPlan = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Container sx={{ color: "white" }}>
      <Box my={4} pt={{ xs: 7, sm: 0, lg: 0, xl: 0 }}>
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
          sx={{ fontSize: '1.5rem' }}
        >
          {t("commissionplan.title")}
        </Typography>
        {/* <Typography variant="body1" component="div" fontWeight={800}>
          {t("tutorial.join.title")}
        </Typography> */}
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          <div dangerouslySetInnerHTML={{ __html: t("commissionplan.para1.title") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("commissionplan.para1.desc")}
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
          sx={{ fontSize: '1.5rem' }}
        >
          {t("commissionplan.para2.title")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("commissionplan.para2.desc")}
          <br />
          <br />
          <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
            <div dangerouslySetInnerHTML={{ __html: t("commissionplan.para2.desc2") }}></div>
          </Typography>
          <br />
          <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
            <div dangerouslySetInnerHTML={{ __html: t("commissionplan.para2.desc3") }}></div>
          </Typography>
          <br />
          <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
            <div dangerouslySetInnerHTML={{ __html: t("commissionplan.para2.desc4") }}></div>
          </Typography>
          <br />
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
          sx={{ fontSize: '1.5rem' }}
        >
          {t("commissionplan.para3.title")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("commissionplan.para3.desc")}
        </Typography>
        <br />
        <TableContainer component={Paper} sx={{ backgroundColor: 'white' }}>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell align="center"><strong>{t("commissionplan.table.column")}</strong></TableCell> */}
                <TableCell align="center"><strong>{t("commissionplan.table.column2")}</strong></TableCell>
                <TableCell align="center"><strong>{t("commissionplan.table.column3")}</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {/* <TableCell align="center">{t("commissionplan.table.row")}</TableCell> */}
                <TableCell align="center">{t("commissionplan.table.col2data1")}</TableCell>
                <TableCell align="center">{t("commissionplan.table.col3data1")}</TableCell>
              </TableRow>
              <TableRow>
                {/* <TableCell align="center">{t("commissionplan.table.row")}</TableCell> */}
                <TableCell align="center">{t("commissionplan.table.col2data2")}</TableCell>
                <TableCell align="center">{t("commissionplan.table.col3data2")}</TableCell>
              </TableRow>
              <TableRow>
                {/* <TableCell align="center">{t("commissionplan.table.row")}</TableCell> */}
                <TableCell align="center">{t("commissionplan.table.col2data3")}</TableCell>
                <TableCell align="center">{t("commissionplan.table.col3data3")}</TableCell>
              </TableRow>
              <TableRow>
                {/* <TableCell align="center">{t("commissionplan.table.row")}</TableCell> */}
                <TableCell align="center">{t("commissionplan.table.col2data4")}</TableCell>
                <TableCell align="center">{t("commissionplan.table.col3data4")}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("commissionplan.para3.desc2")}
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
          sx={{ fontSize: '1.5rem' }}
        >
          {t("commissionplan.para3.subtitle")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          <div dangerouslySetInnerHTML={{ __html: t("commissionplan.para3.desc3") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          <div dangerouslySetInnerHTML={{ __html: t("commissionplan.para3.desc4") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("commissionplan.para3.desc5")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("commissionplan.para3.desc6")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("commissionplan.para3.desc7")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          <div dangerouslySetInnerHTML={{ __html: t("commissionplan.para3.desc8") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          <div dangerouslySetInnerHTML={{ __html: t("commissionplan.para3.desc9") }}></div>
        </Typography>
        <br />
        {/* <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
        {t("commissionplan.para3.desc10")}
        </Typography> */}
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
          sx={{ fontSize: '1.5rem' }}
        >
          {t("commissionplan.para4.title")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("commissionplan.para4.desc")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("commissionplan.para4.desc2")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("commissionplan.para4.desc3")}
        </Typography>
        <br />
        <TableContainer component={Paper} sx={{ backgroundColor: 'white' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center"><strong>{t("commissionplan.table2.column")}</strong></TableCell>
                <TableCell align="center"><strong>{t("commissionplan.table2.column2")}</strong></TableCell>
                <TableCell align="center"><strong>{t("commissionplan.table2.column3")}</strong></TableCell>
                <TableCell align="center"><strong>{t("commissionplan.table2.column4")}</strong></TableCell>
                <TableCell align="center"><strong>{t("commissionplan.table2.column5")}</strong></TableCell>
                <TableCell align="center"><strong>{t("commissionplan.table2.column6")}</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">{t("commissionplan.table2.col1row1")}</TableCell>
                <TableCell align="center">{t("commissionplan.table2.col2row1")}</TableCell>
                <TableCell align="center">{t("commissionplan.table2.col3row1")}</TableCell>
                <TableCell align="center">{t("commissionplan.table2.col4row1")}</TableCell>
                <TableCell align="center">{t("commissionplan.table2.col5row1")}</TableCell>
                <TableCell align="center">{t("commissionplan.table2.col6row1")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">{t("commissionplan.table2.col1row2")}</TableCell>
                <TableCell align="center">{t("commissionplan.table2.col2row2")}</TableCell>
                <TableCell align="center">{t("commissionplan.table2.col3row2")}</TableCell>
                <TableCell align="center">{t("commissionplan.table2.col4row2")}</TableCell>
                <TableCell align="center">{t("commissionplan.table2.col5row2")}</TableCell>
                <TableCell align="center">{t("commissionplan.table2.col6row2")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">{t("commissionplan.table2.col1row3")}</TableCell>
                <TableCell align="center">{t("commissionplan.table2.col2row3")}</TableCell>
                <TableCell align="center">{t("commissionplan.table2.col3row3")}</TableCell>
                <TableCell align="center">{t("commissionplan.table2.col4row3")}</TableCell>
                <TableCell align="center">{t("commissionplan.table2.col5row3")}</TableCell>
                <TableCell align="center">{t("commissionplan.table2.col6row3")}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
          sx={{ fontSize: '1.5rem' }}
        >
          {t("commissionplan.para5.title")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          <div dangerouslySetInnerHTML={{ __html: t("commissionplan.para5.desc") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("commissionplan.para5.desc2")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("commissionplan.para5.desc3")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem', overflowWrap: 'break-word', wordWrap: 'break-word' }}>
          {t("commissionplan.para5.desc4")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          <div dangerouslySetInnerHTML={{ __html: t("commissionplan.para5.desc5") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("commissionplan.para5.desc6")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("commissionplan.para5.desc7")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          <div dangerouslySetInnerHTML={{ __html: t("commissionplan.para5.desc8") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("commissionplan.para5.desc9")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem', overflowWrap: 'break-word', wordWrap: 'break-word' }}>
          {t("commissionplan.para5.desc10")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem', overflowWrap: 'break-word', wordWrap: 'break-word' }}>
          {t("commissionplan.para5.desc11")}
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
          sx={{ fontSize: '1.5rem' }}
        >
          {t("commissionplan.para6.title")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("commissionplan.para6.desc")}
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
          sx={{ fontSize: '1.5rem' }}
        >
          {t("commissionplan.para7.title")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("commissionplan.para7.desc")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("commissionplan.para7.desc2")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("commissionplan.para7.desc3")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("commissionplan.para7.desc4")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("commissionplan.para7.desc5")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("commissionplan.para7.desc6")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("commissionplan.para7.desc7")}
        </Typography>
        <br />
        {/* <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
        >
          {t("responsiblegaming.kycverification.4.title")}
        </Typography>
        <Typography variant="body2" component="div">
          <ol>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.kycverification.4.list.1")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.kycverification.4.list.2")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.kycverification.4.list.3")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.kycverification.4.list.4")}
            </li>
            <li style={{ margin: "16px" }}>
              {t("responsiblegaming.kycverification.4.list.5")}
            </li>
          </ol>
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
        >
          {t("responsiblegaming.kycverification.5.title")}
        </Typography>
        <Typography variant="body2" component="div">
          {t("responsiblegaming.kycverification.5.desc")}
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
        >
          {t("responsiblegaming.kycverification.6.title")}
        </Typography>
        <Typography variant="body2" component="div">
          {t("responsiblegaming.kycverification.6.desc")}
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
        >
          {t("responsiblegaming.kycverification.7.title")}
        </Typography>
        <Typography variant="body2" component="div">
          {t("responsiblegaming.kycverification.7.desc")}
        </Typography> */}
      </Box>
    </Container>
  );
};

export default AffiliateCommissionPlan;
