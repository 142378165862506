import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AccountCircle, Restore, Logout, AttachMoney } from "@mui/icons-material";
import { Grid, Box, Typography, useTheme, Button } from "@mui/material";
import Image from "./Image";
import { useTranslation } from "react-i18next";
import { logout } from "../../features/slices/authSlice";
import { selectCurrentToken } from "../../features/slices/authSlice";
import i18n from "i18next";
import DropdownMenu from "./DropdownMenu";
import * as Constant from "../../features/constant";
import { BiMoneyWithdraw } from "react-icons/bi";

const UserCard = ({ user, isDrawer }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(selectCurrentToken);

  const cardItems = [
    {
      label: i18n.t("walletcard.myaccount"),
      code: "My Account",
      icon: <AccountCircle sx={{ height: 20 }} />,
    },
    // {
    //   label: i18n.t("walletcard.deposit"),
    //   code: "Deposit",
    //   icon: <AttachMoney size={23} />,
    // },
    {
      label: i18n.t("walletcard.withdrawal"),
      code: "Withdrawal",
      icon: <BiMoneyWithdraw size={23} />,
    },
    {
      label: i18n.t("walletcard.logout"),
      code: "Logout",
      icon: <Logout sx={{ height: 20 }} />,
    },
  ];

  const handleCardOnClick = (item) => {
    switch (item?.code) {
      case "Logout":
        dispatch(logout());
        navigate(Constant.getPath("/"));
        break;

      case "Deposit":
        navigate(Constant.getPath("/affiliatedeposit"));
        break;

      case "Withdrawal":
        navigate(Constant.getPath("/affiliatewithdraw"));
        break;

      case "My Account":
        navigate(Constant.getPath("/affiliateprofile"));
        break;

      default:
        navigate(Constant.getPath("/"));
    }
    // if (item?.code === "Logout") {
    //   dispatch(logout());
    //   navigate(Constant.getPath("/"));
    // } else {
    //   navigate(Constant.getPath("/profileWallet"), {
    //     state: {
    //       screen: item?.code,
    //     },
    //   });
    // }
  };

  // const handleGoToProfile = () => {
  //   navigate("/profileWallet", {
  //     state: {
  //       screen: "Deposit",
  //     },
  //   });
  // };

  const renderRankBox = (rank) => (
    <>
      {rank && (
        <Box>
          <Typography
            variant="body2"
            color={theme.palette.primary.main}
            fontWeight={600}
            fontStyle="italic"
          >
            {rank}
          </Typography>
        </Box>
      )}
    </>
  );
  return (
    <>
      {isDrawer ? (
        <Button onClick={null} sx={{ width: "100%", display: "block" }}>
          <Grid sx={{ py: 1 }} container justifyContent="center">
            <Grid item>
              <Box sx={{ mt: 1 }}>
                <Typography
                  display="block"
                  variant="sublabel1"
                  textAlign="center"
                  pb={1}
                  lineHeight={0}
                  sx={{
                    fontWeight: "300",
                    fontSize: "0.8em",
                    textTransform: "UPPERCASE",
                  }}
                  color="white"
                >
                  <Image
                    src={process.env.REACT_APP_LOGO} // Replace with the actual path to your logo
                    alt="Logo"
                    sx={{
                      height: "32px", // Adjust height
                      width: "32px", // Adjust width
                      margin: "auto", // Centers the avatar
                    }}
                  />
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Button>
      ) : (
        <DropdownMenu
          variant="text"
          sxButton={{
            display: "flex",
            // width: "190px",
            py: "10px",
            ":hover": {
              color: theme.palette.primary.main,
              bgcolor: theme.palette.primary.main + "00",
            },
          }}
          sxMenu={{
            mt: "45px",
          }}
          items={cardItems}
          handleOnClickEvent={handleCardOnClick}
        >
          <Grid sx={{ flexGrow: 1 }} container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item display="flex" alignSelf="center">
                  <Box
                    display="flex"
                    justifyContent="center"
                    pl="15px"
                    pr="20px"
                  >
                    <AccountCircle />
                  </Box>
                </Grid>
                <Grid item display="flex" alignSelf="center">
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    {renderRankBox(user?.playerRank)}
                    <Typography variant="body2" color="white">
                      {`${process.env.REACT_APP_CURRENCY_CODE} ${user?.currentCredit}`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DropdownMenu>
      )}
    </>
  );
};

UserCard.propTypes = {
  user: PropTypes.object,
  isDrawer: PropTypes.bool,
};

UserCard.defaultProps = {
  user: {},
  isDrawer: false,
};

export default UserCard;
