import React from "react";
import { useNavigate } from "react-router-dom";
import {
  useTheme,
  Button,
  Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";

import Image from "../common/Image";

import * as Constant from "../../features/constant";
import HomeIcon from "../../icons/header/HomeIcon";

const DesktopHeaderItemsAffiliate = ({ label, code, selectedPath, setSelectedPath }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <Button
        variant="outlined"
        sx={{
          color: selectedPath ? theme.palette.tertiary.main : "white",
          pt: 0,
          ":hover": {
            color: theme.palette.tertiary.main,
            borderColor: "transparent"
          },
          borderRadius: 0,
          borderColor: "transparent",
          pl: 0
        }}
        disableRipple
        onClick={() => {
          navigate(
            code === "home"
              ? Constant.getPath("/")
              : code?.toLowerCase() === "affiliatecommissionplan"
                ? Constant.getPath("/AffiliateCommissionPlan")
                : code?.toLowerCase() === "affiliateterms"
                  ? Constant.getPath("/AffiliateTerms")
                  : code?.toLowerCase() === "affiliatefaq"
                    ? Constant.getPath("/AffiliateFaq")
                    : code?.toLowerCase() === "affiliatecontactus"
                      ? Constant.getPath("/AffiliateContactUs")
                      : code?.toLowerCase() === "affiliatereport"
                        ? Constant.getPath("/AffiliateReport")
                        : code?.toLowerCase() === "affiliateoverview"
                          ? Constant.getPath("/AffiliateOverview")
                          : null
          );
          setSelectedPath(
            code === "home"
              ? Constant.getPath("/")
              : code?.toLowerCase() === "affiliatecommissionplan"
                ? Constant.getPath("/AffiliateCommissionPlan")
                : code?.toLowerCase() === "affiliateterms"
                  ? Constant.getPath("/AffiliateTerms")
                  : code?.toLowerCase() === "affiliatefaq"
                    ? Constant.getPath("/AffiliateFaq")
                    : code?.toLowerCase() === "affiliatecontactus"
                      ? Constant.getPath("/AffiliateContactUs")
                      : code?.toLowerCase() === "affiliatereport"
                        ? Constant.getPath("/AffiliateReport")
                        : code?.toLowerCase() === "affiliateoverview"
                          ? Constant.getPath("/AffiliateOverview")
                          : null
          );
        }}
      >
        {label === t("header.home") ? (
          <HomeIcon width={25} height={25} color={selectedPath ? theme.palette.primary.main : "white"} />
        ) : undefined}

        <Typography
          variant="sublabel1"
          textAlign="center"
          fontWeight={600}
          marginLeft="5px"
        >
          {label}
        </Typography>
      </Button>
    </>
  );
};

export default DesktopHeaderItemsAffiliate;
