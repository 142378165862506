import React, { useState, useEffect } from 'react';
import '../components/Affiliate/reportstyles.css'
import { useNavigate } from 'react-router-dom';
import { Box, useTheme } from "@mui/material";
import AffiliateReportTables from '../components/Affiliate/AffiliateReportTables';
import interact from 'interactjs';
import { useTranslation } from "react-i18next";
import AffiliateSideNav from '../components/Affiliate/AffiliateSideNav';
import AffiliateWallet from '../components/Affiliate/AffiliateWallet';


const AffiliateReport = () => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchParams, setSearchParams] = useState({ startDate: '', endDate: '' });

  const reportMenus = useState(t("reportItems"));

  interact('.draggable-scrollbar-container').draggable({
    inertia: true,
    autoScroll: true,
    listeners: {
      move(event) {
        const scrollContainer = document.querySelector('.draggable-scrollbar-container');
        const deltaX = event.dx;
        scrollContainer.scrollLeft -= deltaX;
      }
    }
  });

  const [selectedMenu, setSelectedMenu] = useState(reportMenus[0][0].code);

  const handleMenuClick = async (menuCode) => {
    setSelectedMenu(menuCode);
  };

  const handleSearchClick = () => {
    setSearchParams({ startDate, endDate });
  };

  const getMostRecentMonday = () => {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
    const mondayOffset = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // Calculate offset
    const recentMonday = new Date(today);
    recentMonday.setDate(today.getDate() + mondayOffset);
    return recentMonday.toISOString().split('T')[0]; // Return in YYYY-MM-DD format
  };

  useEffect(() => {
    const monday = getMostRecentMonday();
    const today = new Date().toISOString().split('T')[0];
    setStartDate(monday);
    setEndDate(today);
    setSearchParams({ startDate: monday, endDate: today });
  }, []);



  return (
    <Box className='container' pt={{ xs: 7, sm: 0, lg: 0, xl: 0 }}>
      <AffiliateSideNav />
      <div className='menuWrapper'>
        <AffiliateWallet />
        <div className='report-menu-container draggable-scrollbar-container'>
          <div class="scroll-content">
            {reportMenus[0].map((menu) => {
              return <button className='scroll-button' style={menu.code === selectedMenu ? { backgroundColor: theme.palette.primary.main, fontWeight: "bold" } : {}} onClick={() => handleMenuClick(menu.code)}>{menu.title}</button>
            })}
          </div>
        </div>
        {selectedMenu !== "teamSize" && (
          <div className='datePickerContainer'>
            <label htmlFor="from-date">From</label>
            <input
              type="date"
              id="from-date"
              name="from-date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <label htmlFor="to-date">To</label>
            <input
              type="date"
              id="to-date"
              name="to-date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
            <button className='searchButton' onClick={handleSearchClick}>Search</button>
          </div>
        )}
        <div className='report-container' style={{ marginTop: 10 }}>
          <AffiliateReportTables menucode={selectedMenu} startDate={searchParams.startDate} endDate={searchParams.endDate} />
        </div>
      </div>
    </Box>
  );
};

export default AffiliateReport;
