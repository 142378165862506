import React from "react";
import { Container, Box, useTheme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import "../utils/en.json";

const AffiliateTerms = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Container sx={{ color: "white" }}>
      <Box my={4} pt={{ xs: 7, sm: 0, lg: 0, xl: 0 }}>
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
        >
          {t("termandconditions.title")}
        </Typography>
        <br/>
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
          sx={{ fontSize: '1.5rem' }}
        >
         <div dangerouslySetInnerHTML={{ __html: t("termandconditions.para1.title") }}></div>
        </Typography>
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
        <div dangerouslySetInnerHTML={{ __html: t("termandconditions.para1.desc") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("termandconditions.para1.desc2")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
        <div dangerouslySetInnerHTML={{ __html: t("termandconditions.para1.desc3") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("termandconditions.para1.desc4")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
        <div dangerouslySetInnerHTML={{ __html: t("termandconditions.para1.desc5") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("termandconditions.para1.desc6")}
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
          sx={{ fontSize: '1.5rem' }}
        >
          {t("termandconditions.para2.title")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
        <div dangerouslySetInnerHTML={{ __html: t("termandconditions.para2.desc") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("termandconditions.para2.desc2")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
        <div dangerouslySetInnerHTML={{ __html: t("termandconditions.para2.desc3") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("termandconditions.para2.desc4")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
        <div dangerouslySetInnerHTML={{ __html: t("termandconditions.para2.desc5") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("termandconditions.para2.desc6")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
        <div dangerouslySetInnerHTML={{ __html: t("termandconditions.para2.desc7") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("termandconditions.para2.desc8")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
        <div dangerouslySetInnerHTML={{ __html: t("termandconditions.para2.desc9") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("termandconditions.para2.desc10")}
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
          sx={{ fontSize: '1.5rem' }}
        >
          {t("termandconditions.para3.title")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
        <div dangerouslySetInnerHTML={{ __html: t("termandconditions.para3.desc") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("termandconditions.para3.desc2")}
        </Typography>
        <br />
       <TableContainer component={Paper} sx={{ backgroundColor: 'white' }}>
        <Table>
          <TableHead>
            <TableRow>
              {/* <TableCell align="center"><strong>{t("termandconditions.table.column")}</strong></TableCell> */}
              <TableCell align="center"><strong>{t("termandconditions.table.column2")}</strong></TableCell>
              <TableCell align="center"><strong>{t("termandconditions.table.column3")}</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          <TableRow>
              {/* <TableCell align="center">{t("termandconditions.table.row")}</TableCell> */}
              <TableCell align="center">{t("termandconditions.table.col2data1")}</TableCell>
              <TableCell align="center">{t("termandconditions.table.col3data1")}</TableCell>
            </TableRow>
            <TableRow>
              {/* <TableCell align="center">{t("termandconditions.table.row")}</TableCell> */}
              <TableCell align="center">{t("termandconditions.table.col2data2")}</TableCell>
              <TableCell align="center">{t("termandconditions.table.col3data2")}</TableCell>
            </TableRow>
            <TableRow>
              {/* <TableCell align="center">{t("termandconditions.table.row")}</TableCell> */}
              <TableCell align="center">{t("termandconditions.table.col2data3")}</TableCell>
              <TableCell align="center">{t("termandconditions.table.col3data3")}</TableCell>
            </TableRow>
            <TableRow>
              {/* <TableCell align="center">{t("termandconditions.table.row")}</TableCell> */}
              <TableCell align="center">{t("termandconditions.table.col2data4")}</TableCell>
              <TableCell align="center">{t("termandconditions.table.col3data4")}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("termandconditions.para3.desc3")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
        <div dangerouslySetInnerHTML={{ __html: t("termandconditions.para3.subtitle") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
        <div dangerouslySetInnerHTML={{ __html: t("termandconditions.para3.desc4") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
        <div dangerouslySetInnerHTML={{ __html: t("termandconditions.para3.desc5") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
        <div dangerouslySetInnerHTML={{ __html: t("termandconditions.para3.desc6") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("termandconditions.para3.desc7")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
        <div dangerouslySetInnerHTML={{ __html: t("termandconditions.para3.desc8") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
        <div dangerouslySetInnerHTML={{ __html: t("termandconditions.para3.subdesc") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
        <div dangerouslySetInnerHTML={{ __html: t("termandconditions.para3.subdesc2") }}></div>
        </Typography>
        <br />
        {/* <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("termandconditions.para3.desc9")}
        </Typography>
        <br /> */}
        {/* <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
        <div dangerouslySetInnerHTML={{ __html: t("termandconditions.para3.desc10") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
        <div dangerouslySetInnerHTML={{ __html: t("termandconditions.para3.desc11") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
        <div dangerouslySetInnerHTML={{ __html: t("termandconditions.para3.desc12") }}></div>
        </Typography>
        <br /> */}
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
        <div dangerouslySetInnerHTML={{ __html: t("termandconditions.para3.desc13") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
        <div dangerouslySetInnerHTML={{ __html: t("termandconditions.para3.desc14") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
        <div dangerouslySetInnerHTML={{ __html: t("termandconditions.para3.desc15") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
        <div dangerouslySetInnerHTML={{ __html: t("termandconditions.para3.desc16") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
        <div dangerouslySetInnerHTML={{ __html: t("termandconditions.para3.desc17") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
        <div dangerouslySetInnerHTML={{ __html: t("termandconditions.para3.desc18") }}></div>
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
          sx={{ fontSize: '1.5rem' }}
        >
          <div dangerouslySetInnerHTML={{ __html: t("termandconditions.para4.title") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
        <div dangerouslySetInnerHTML={{ __html: t("termandconditions.para4.desc") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("termandconditions.para4.desc2")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
        <div dangerouslySetInnerHTML={{ __html: t("termandconditions.para4.desc3") }}></div>
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("termandconditions.para4.desc4")}
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
          sx={{ fontSize: '1.5rem' }}
        >
          {t("termandconditions.para5.title")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("termandconditions.para5.desc")}
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
          sx={{ fontSize: '1.5rem' }}
        >
          {t("termandconditions.para6.title")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("termandconditions.para6.desc")}
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="h6"
          fontWeight={800}
          color={theme.palette.primary.main}
          sx={{ fontSize: '1.5rem' }}
        >
          {t("termandconditions.para7.title")}
        </Typography>
        <br />
        {/* <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("termandconditions.para7.desc")}
        </Typography>
        <br /> */}
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("termandconditions.para7.desc2")}
        </Typography>
        <br />
        <Typography variant="body2" component="div" sx={{ fontSize: '1rem' }}>
          {t("termandconditions.para7.desc3")}
        </Typography>
        <br />
      </Box>
    </Container>
  );
};

export default AffiliateTerms;
