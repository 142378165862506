import React, { useEffect } from 'react';
import '../components/Affiliate/reportstyles.css'
import * as Constant from "../features/constant";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import {
  Box,
  Grid,
  TextField,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Paper,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { useUpdateProfileMutation } from '../features/api/userApiSlice';
import { useState } from 'react';
import PasswordInput from '../components/Affiliate/PasswordInput';
import AffiliateSideNav from '../components/Affiliate/AffiliateSideNav';
import AffiliateWallet from '../components/Affiliate/AffiliateWallet';
import ChangePassword from '../components/wallet/ChangePassword';
import FormTextField from '../components/form/FormTextField';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { setAlert } from '../features/slices/alertSlice';
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useLoginMutation } from '../features/api/authApiSlice';


const AffiliateChangePassword = () => {

  const navigate = useNavigate();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showReEnterPassword, setShowReEnterPassword] = useState(false);
  const [login, { checkingdata, isChecking, mismatch }] = useLoginMutation();


  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword(prevState => !prevState);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(prevState => !prevState);
  };

  const toggleReEnterPasswordVisibility = () => {
    setShowReEnterPassword(prevState => !prevState);
  };


  const dispatch = useDispatch();
  const { t } = useTranslation();

  const passwordSchema = yup.object().shape({
    currentPassword: yup
    .string()
    .required(t("validation.currentpassword")),

    newPassword: yup
    .string()
    .required(t("validation.newpassword"))
    .notOneOf([yup.ref('currentPassword')], t("validation.passwordnotsame")),
    
    reEnterPassword: yup
      .string()
      .required(t("validation.reenterpassword"))
      .oneOf([yup.ref("newPassword"), null], t("validation.passwordnotmatch")),
  });

  const [updateProfile, { data, isLoading, isError, isSuccess, error }] =
    useUpdateProfileMutation();

  const { control, handleSubmit, reset, getValues } = useForm({
    resolver: yupResolver(passwordSchema),
  });

  const submitPassword = async (data) => {

    try {
      const currentPassword = getValues("currentPassword");
      const username = localStorage.getItem('username');

      let formData2 = new FormData();
      formData2.append("username", username);
      formData2.append("password", currentPassword);
      formData2.append("grant_type", "password");

      // console.log(username);
      // console.log(currentPassword);

      // Trigger the login mutation
      const result = await login(formData2).unwrap();

      if (result) {
        // console.log("Current password is same: ", result);
        let formData = {
          currentPassword: data?.currentPassword,
          password: data?.newPassword,
        };
        await updateProfile(formData).unwrap();
      
        
        
      }
    } catch (err) {
      // console.error("Current password is not same: ", err);
      const errorMessage = err?.data?.message || "Failed to update password.";

      dispatch(
        setAlert({
          isOpen: true,
          message: errorMessage,
          variant: "error",
        })
      );
    }
  };



  useEffect(() => {
    if (!isLoading && isSuccess) {
      dispatch(
        setAlert({
          isOpen: true,
          message: t("walletcard.changepasswordpage.successmessage"),
          variant: "success",
        })
      );
      reset();
    }
    if (isError) {
      dispatch(
        setAlert({
          isOpen: true,
          message: error.data.message,
          variant: "error",
        })
      );
    }

    

      //method to get single datafield from localstorage along with manual saving data in apislice
    // const username = localStorage.getItem('username');
    // if (username) {
    //   console.log('Current username:', username);
    // } else {
    //   console.log('No username found in local storage.');
    // }

  }, [isLoading, isSuccess, isError]);


  return (
    <>
      <Box className='container' pt={{ xs: 7, sm: 0, lg: 0, xl: 0 }}>
        <AffiliateSideNav />
        <div className='menuWrapper'>
          <AffiliateWallet />
          <div className='report-container'>
            <Box display="flex" flexDirection="column" >
              <form onSubmit={handleSubmit(submitPassword)}>
                <FormTextField
                  label={t("walletcard.changepasswordpage.currentpassword")}
                  control={control}
                  controllerName="currentPassword"
                  defaultValue={""}
                  type={showCurrentPassword ? "text" : "password"}
                  size="medium"
                  showPasswordToggle={true}
                  onTogglePassword={toggleCurrentPasswordVisibility}
                  style={{
                    width: {
                      xs: "100%",
                      md: "60%"
                    },
                    display: "flex",
                    flexDirection: "column",
                    mt: "15px",
                  }}
                />
                <FormTextField
                  label={t("walletcard.changepasswordpage.newpassword")}
                  control={control}
                  controllerName="newPassword"
                  defaultValue={""}
                  type={showNewPassword ? "text" : "password"}
                  size="medium"
                  showPasswordToggle={true}
                  onTogglePassword={toggleNewPasswordVisibility}
                  style={{
                    width: {
                      xs: "100%",
                      md: "60%"
                    },
                    display: "flex",
                    flexDirection: "column",
                    mt: "15px",
                  }}
                />
                <FormTextField
                  label={t("walletcard.changepasswordpage.reenterpassword")}
                  control={control}
                  controllerName="reEnterPassword"
                  defaultValue={""}
                  type={showReEnterPassword ? "text" : "password"}
                  size="medium"
                  showPasswordToggle={true}
                  onTogglePassword={toggleReEnterPasswordVisibility}
                  style={{
                    width: {
                      xs: "100%",
                      md: "60%"
                    },
                    display: "flex",
                    flexDirection: "column",
                    mt: "15px",
                  }}
                />
                <Box alignSelf="center" mb="15px" mt="25px">
                  <Button variant="contained" type="submit" sx={{ padding: '10px 20px', fontWeight: 'bold', backgroundColor: "#EE2842", width: { xs: "100%", sm: "auto" } }}>
                    {t("walletcard.submit")}
                  </Button>
                </Box>
              </form>
            </Box>
          </div >
        </div >
      </Box >
    </>
  );
};

export default AffiliateChangePassword;
