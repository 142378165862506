import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormControl, TextField, InputAdornment, IconButton } from "@mui/material";
import { Controller } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const FormTextField = ({
  control,
  defaultValue,
  label,
  controllerName,
  type,
  size,
  placeholder,
  InputProps,
  disabled,
  InputLabelProps,
  margin,
  style,
  note,
  dynamicValue,  
  showPasswordToggle, 
  onTogglePassword ,
  onChange, // Added onChange prop
  width
  }) => {

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
     setShowPassword(!showPassword);
  };
                      

  return (
    <FormControl
      sx={
        Object.keys(style).length !== 0
          ? style
          : {
              display: "flex",
              flexDirection: "column",
              mt: "15px",
              width: width,
            }
      }
    >
      <Controller
        name={controllerName}
        control={control}
        defaultValue={dynamicValue ? dynamicValue : defaultValue}
        render={({ field: { onChange:fieldOnChange, value }, fieldState: { error } }) => (
          <TextField
            label={label}
            value={dynamicValue ? dynamicValue : value}
            onChange={(e) => {
              fieldOnChange(e);
              if (onChange) {
                onChange(e);
              }
            }}
            error={!!error}
            helperText={error ? error.message : note}
            type={type}
            size={size}
            placeholder={placeholder}
            disabled={disabled}
            InputLabelProps={InputLabelProps}
            margin={margin}
            fullWidth
            fontSize={"0.5rem"}
            InputProps={{
              ...InputProps,
              // Add adornment for the eye icon
              endAdornment: showPasswordToggle && (
                <InputAdornment position="end">
                  <IconButton onClick={onTogglePassword} edge="end">
                  {type === "password" ? <FaEyeSlash  style={{ color: "white" }} /> : <FaEye  style={{ color: "white" }} />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
};

FormTextField.propTypes = {
  label: PropTypes.string,
  controllerName: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  margin: PropTypes.string,
  style: PropTypes.object,
  note: PropTypes.string,
  showPasswordToggle: PropTypes.bool, 
  onTogglePassword: PropTypes.func, 
};

FormTextField.defaultProps = {
  label: "",
  controllerName: "",
  type: "text",
  size: "medium",
  placeholder: "",
  disabled: false,
  margin: "",
  style: {},
  note: "",
  showPasswordToggle: false,
};

export default FormTextField;
