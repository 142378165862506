import React from 'react';
import { useMediaQuery, useTheme } from "@mui/material";
import { useGetProfileQuery } from "../../features/api/userApiSlice";
import Backdrop from '../common/Backdrop';
import { useTranslation } from "react-i18next";

const AffiliateWallet = () => {
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

    const { t } = useTranslation();

    const {
        data: user,
        isFetching: isUserFetching,
        isLoading: isUserLoading,
    } = useGetProfileQuery({ prefetch: true });

    if (
        isUserFetching ||
        isUserLoading
    ) {
        return <Backdrop />;
    }
    return (
        !mobileView ? (
            <div className='walletwrapper'>
                <div className='walletBalance'>
                    {/* <div className='wallet'>
                        Deposit Wallet <p>{process.env.REACT_APP_CURRENCY_CODE} 0.00</p>
                    </div> */}
                    <div className='wallet'>
                    {t("wallet.walletbalance")} <p>{process.env.REACT_APP_CURRENCY_CODE} {user?.result?.currentCredit}</p>
                    </div>

                    {/* <div class="tooltip-container">
                        <div className='wallet'>
                            <button class="currency-button">
                                <img src="/assets/img/reward_3.png" alt="Currency Exchange" />
                                Currency Exchange
                            </button>
                            <p class="tooltip-text">1 PHP Convert 1.00 USD</p>
                        </div>
                    </div> */}
                </div>
            </div>
        ) : (
            <div className='walletwrapper'>
                <div className='walletBalance'>
                    {/* <div className='wallet'>
                        Deposit Wallet
                        <p>{process.env.REACT_APP_CURRENCY_CODE} 0.00</p>
                    </div> */}
                    <div className='wallet'>
                    {t("wallet.walletbalance")} 
                        <p>{process.env.REACT_APP_CURRENCY_CODE} {user?.result?.currentCredit}</p>
                    </div>
                </div>
            </div>
        )
    );
};

export default AffiliateWallet;