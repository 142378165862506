import { apiSlice } from "../api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Login"],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/public/api/v1/affiliateLogin",
        method: "POST",
        body: credentials,
      }),
      providesTags: ["Login"],
      invalidatesTags: ["Profile"],
      transformResponse: (response, meta) => {
        if (response.responseCode === 401 || response.responseCode === 500) {
          throw new Error(response.message || 'Unauthorized');
        }
        return response;
      },
    }),

    // login: builder.mutation({
    //   query: (credentials) => ({ 
    //     url: "/login",
    //     method: "POST",
    //     body: credentials,
    //   }),
    //   providesTags: ["Login"],
    //   invalidatesTags: ["Profile", "BonusList", "GameCategory"],
    // }),
  }),
});

export const { useLoginMutation } = authApiSlice;
