

import React, { useState } from "react";
import { CopyAll } from "@mui/icons-material";
import { Tooltip, Button, Box } from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";

const AffiliateCopiedTooltip = ({ displayText, text }) => {
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleClick = () => {
    setOpenTooltip(true);

    // Set a timeout to close the tooltip after a certain duration (e.g., 2000ms = 2 seconds)
    setTimeout(() => {
      setOpenTooltip(false);
    }, 500);
  };

  return (
    <>
      <Tooltip
        open={openTooltip}
        title={"Copied"}
        arrow
        placement="top"
        sx={{ position: "absolute", right: 60 }}
      />
      <CopyToClipboard text={text} onCopy={() => handleClick()}>
        <button className="button button-share">
          {displayText}
          <CopyAll />
        </button>
      </CopyToClipboard>
    </>
  );
};

export default AffiliateCopiedTooltip;