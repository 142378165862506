import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  refreshToken: null,
  userId: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action) => {

      if(action.payload?.data !== undefined) {
        const data = action.payload;
        state.token = data.data.access_token;
        state.refreshToken = data.data.refresh_token;
        state.userId = data.data
      } else {
        const { access_token, refresh_token } = action.payload;
        state.token = access_token;
        state.refreshToken = refresh_token;
      }

    },
    logout: (state, action) => {
      state.token = null;
      state.refreshToken = null;
    },
  },
});

export const { setCredentials, logout } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
