import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Divider,
  Box,
  Typography,
  Grid,
  IconButton,
  useTheme,
  darken,
} from "@mui/material";
import { useGetProfileQuery } from "../../features/api/userApiSlice";
import { selectCurrentToken } from "../../features/slices/authSlice";
import { Forward, Refresh } from "@mui/icons-material";
import Image from "./Image";
import { useTranslation } from "react-i18next";
import { apiSlice } from "../../features/api/apiSlice";
import * as Constant from "../../features/constant";

const PATH = process.env.PUBLIC_URL;

const WalletCard = ({ screens, token }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const domain = window.location.origin;
  const apkUrl = domain.replace("/\\/(en|bn)-bd\\//, \"/\"", "/") + "/bagh8.apk";
  const {
    data: user,
    isFetching: isUserFetching,
    isLoading: isUserLoading,
  } = useGetProfileQuery({ prefetch: true });

  return (
      <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // borderRadius: 4,
            // border: 1,
            p: 1,
            backgroundColor: 'black',
          }}
      >
        <Grid container pt={0} spacing={1}>
          {screens?.map((screen) => (
              <Grid
                  item
                  xs={3}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    cursor: "pointer",
                    p: 0,
                  }}
                  onClick={() => {

                    if (!token && screen?.label?.toLowerCase() !== "referral" && screen?.label?.toLowerCase() !== "app") {
                      navigate(Constant.getPath("/login"));
                    } else {
                      if (
                          screen?.label?.toLowerCase() === "promotion" ||
                          screen?.label?.toLowerCase() === "vip"
                      ) {
                        navigate(Constant.getPath("/" + screen?.label));
                      } else if (screen?.label?.toLowerCase() === "app" || screen?.label?.toLowerCase() === "অ্যাপ") {
                        window.open(apkUrl);
                      } else {
                        if (screen?.label?.toLowerCase() === "referral") {
                          navigate(Constant.getPath("/affiliate"));
                        } else {
                          navigate(Constant.getPath("/profileWallet"), {
                            state: {screen: screen?.code},
                          });
                        }
                      }
                    }
                  }}
              >
                {screen?.icon}
                <Typography textAlign="center" fontSize={10} color="white">
                  {screen?.label}
                </Typography>
              </Grid>
          ))}
        </Grid>
      </Box>
  );
};

export default WalletCard;
