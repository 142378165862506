import React from 'react';
import '../components/Affiliate/reportstyles.css'
import * as Constant from "../features/constant";
import { useNavigate } from 'react-router-dom';
import AffiliateSideNav from '../components/Affiliate/AffiliateSideNav';
import AffiliateWallet from '../components/Affiliate/AffiliateWallet';
import { Box } from '@mui/material';
import { useMediaQuery, useTheme } from "@mui/material";

const AffiliateDeposit = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box className='container' pt={{ xs: 7, sm: 0, lg: 0, xl: 0 }}>
      <AffiliateSideNav />
      <div className='menuWrapper'>
        <AffiliateWallet />
        <div className='datePickerContainer'>
          <label htmlFor="from-date">From</label>
          <input type="date" id="from-date" name="from-date" />
          <label htmlFor="to-date">To</label>
          <input type="date" id="to-date" name="to-date" />
          <input type="text" placeholder="Login" />
          <select>
            <option value="member">Member</option>
            <option value="admin">Admin</option>
          </select>
          <button className='searchButton'>Search</button>
        </div>
        <div className='report-container'>
          <p>place holder deposit</p>
        </div>
      </div>
    </Box>
  );
};

export default AffiliateDeposit;
