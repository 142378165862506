import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Box,
} from '@mui/material';
import { useTranslation } from "react-i18next";
import { useGetDownlineDepositHistoryQuery,useGetDownlineBetHistoryQuery,useGetDownlineFirstDepositDetailsQuery} from '../../features/api/userApiSlice';
import {
  useGetDownlineIndividualSummaryQuery,
  useGetAffiliateDownlineRegisterDataQuery,
  useGetAffiliateExpensesDetailQuery,
  useGetAffiliateDownlineBetProviderQuery,
  useGetDepositTotalCountReportQuery,
  useGetWithdrawalTotalCountReportQuery,
  useGetAffiliateDownlineBonusDetailQuery,
  useGetAffiliateCommissionDetailQuery
} from '../../features/api/reportApiSlice';
import moment from 'moment';
import CustomPagination from './CustomPagination';
import interact from 'interactjs';
import { Provider } from 'react-redux';

const AffiliateReportPopUp = ({ open, handleClose, selectedData, startDate, endDate, buttonClicked }) => {



// console.log(selectedData);
 //console.log(startDate);
// console.log(endDate);
// console.log(buttonClicked);
  const { t } = useTranslation();

  //these 3 field might have to use for double dialog popup for commission table
  const [opendialog2, setOpenDialog2] = useState(false);
  const [openDialog, setOpenDialog] = useState(null);
  const [selectedData2, setSelectedData2] = useState(null);
  const clickableColumns = ['TotalCompanyWinLoss', 'Expenses', 'CalculatedCommission', 'Action', 'MoreDetails'];

  const handleRowClick = (column, rowData) => {

    //modify logic handling for opening second dialog table
    if (clickableColumns.includes(column)) {
      // alert(`You clicked on ${column}: ${rowData[column]}`);
      setSelectedData2(rowData); // Set the new table data
      setOpenDialog2(true); // Open the pop-up dialog

    }
  };

  const depositHistoryColumn = [
    { label: t("reportcolumns.dialogdeposithistory.id"), value: "Id" },
    { label: t("reportcolumns.dialogdeposithistory.method"), value: "Method" },
    { label: t("reportcolumns.dialogdeposithistory.bankname"), value: "BankName" },
    { label: t("reportcolumns.dialogdeposithistory.amount"), value: "Amount" },
    { label: t("reportcolumns.dialogdeposithistory.depositdate"), value: "DepositDate" },
    { label: t("reportcolumns.dialogdeposithistory.approveddate"), value: "ApprovedDate" },
    { label: t("reportcolumns.dialogdeposithistory.status"), value: "Status" },
  ];

  const betHistoryColumn = [
    { label: t("reportcolumns.dialogbethistory.member"), value: "Member" },
    { label: t("reportcolumns.dialogbethistory.provider"), value: "Provider" },
    { label: t("reportcolumns.dialogbethistory.turnover"), value: "Turnover" },
    { label: t("reportcolumns.dialogbethistory.winloss"), value: "WinLoss" },
  ];

  const expensesDetailColumn = [
    { label: t("reportcolumns.dialogexpensesdetail.item"), value: "Item" },
    { label: t("reportcolumns.dialogexpensesdetail.totalvalue"), value: "TotalValue" },
  ];

  const expensesDetailRow = [
    t("reportcolumns.dialogexpensesdetail.adjustmentamount") ,
    t("reportcolumns.dialogexpensesdetail.bonusamount"),
    t("reportcolumns.dialogexpensesdetail.rebateamount"),
    t("reportcolumns.dialogexpensesdetail.rewardamount") ,
]

  const memberSummaryColumn = [
    { label: t("reportcolumns.dialogmembersummary.memberid"), value: "MemberId" },
    { label: t("reportcolumns.dialogmembersummary.username"), value: "Username" },
    { label: t("reportcolumns.dialogmembersummary.totaldeposit"), value: "TotalDeposit" },
    { label: t("reportcolumns.dialogmembersummary.totalwithdrawal"), value: "TotalWithdrawal" },
    { label: t("reportcolumns.dialogmembersummary.adjustmentamount"), value: "AdjustmentAmount" },
    { label: t("reportcolumns.dialogmembersummary.totalbetcount"), value: "TotalBetCount" },
    { label: t("reportcolumns.dialogmembersummary.totalvalidbet"), value: "TotalValidBet" },
    { label: t("reportcolumns.dialogmembersummary.playerwinloss"), value: "PlayerWinLoss" },
    { label: t("reportcolumns.dialogmembersummary.totalbonus"), value: "TotalBonus" },
    { label: t("reportcolumns.dialogmembersummary.totalrebate"), value: "TotalRebate" },
    { label: t("reportcolumns.dialogmembersummary.totalreward"), value: "TotalReward" },
    { label: t("reportcolumns.dialogmembersummary.totalwinloss"), value: "TotalWinLoss" },
  ];

  const bettingsValidBetColumn = [
    { label: t("reportcolumns.dialogbettingsvalidbet.username"), value: "Username" },
    { label: t("reportcolumns.dialogbettingsvalidbet.providername"), value: "ProviderName" },
    { label: t("reportcolumns.dialogbettingsvalidbet.gametype"), value: "GameType" },
    { label: t("reportcolumns.dialogbettingsvalidbet.betamount"), value: "BetAmount" },
    { label: t("reportcolumns.dialogbettingsvalidbet.validbetamount"), value: "ValidBetAmount" },
    { label: t("reportcolumns.dialogbettingsvalidbet.winlossamount"), value: "WinLossAmount" },
    { label: t("reportcolumns.dialogbettingsvalidbet.turnover"), value: "Turnover" },
    { label: t("reportcolumns.dialogbettingsvalidbet.settletime"), value: "SettleTime" },

  ];

  const depositTotalColumn = [
    { label: t("reportcolumns.dialogdeposittotal.username"), value: "Username" },
    { label: t("reportcolumns.dialogdeposittotal.depositid"), value: "DepositId" },
    { label: t("reportcolumns.dialogdeposittotal.depositmethod"), value: "DepositMethod" },
    { label: t("reportcolumns.dialogdeposittotal.bankname"), value: "BankName" },
    { label: t("reportcolumns.dialogdeposittotal.receivedamount"), value: "ReceivedAmount" },
    { label: t("reportcolumns.dialogdeposittotal.deposittime"), value: "DepositTime" },
    { label: t("reportcolumns.dialogdeposittotal.approvedtime"), value: "ApprovedTime" },

  ];

  const withdrawalTotalColumn = [
    { label: t("reportcolumns.dialogwithdrawaltotal.username"), value: "Username" },
    { label: t("reportcolumns.dialogwithdrawaltotal.withdrawid"), value: "WithdrawId" },
    { label: t("reportcolumns.dialogwithdrawaltotal.withdrawtype"), value: "WithdrawType" },
    { label: t("reportcolumns.dialogwithdrawaltotal.bankname"), value: "BankName" },
    { label: t("reportcolumns.dialogwithdrawaltotal.withdrawamount"), value: "WithdrawAmount" },
    { label: t("reportcolumns.dialogwithdrawaltotal.withdrawtime"), value: "WithdrawTime" },
    { label: t("reportcolumns.dialogwithdrawaltotal.approvedtime"), value: "ApprovedTime" },

  ];

  
  const firstTimeDepositColumn = [
    { label: t("reportcolumns.firsttimedeposit.username"), value: "Username" },
    { label: t("reportcolumns.firsttimedeposit.currency"), value: "Currency" },
    { label: t("reportcolumns.firsttimedeposit.firsttimedepositdate"), value: "FirstTimeDepositDate" },
    { label: t("reportcolumns.firsttimedeposit.amount"), value: "Amount" },
  ];

  const userRegisterColumn = [
    { label: t("reportcolumns.dialoguserregister.memberid"), value: "MemberId" },
    { label: t("reportcolumns.dialoguserregister.username"), value: "Username" },
    { label: t("reportcolumns.dialoguserregister.email"), value: "Email" },
    { label: t("reportcolumns.dialoguserregister.mobileno"), value: "MobileNo" },
    { label: t("reportcolumns.dialoguserregister.lastlogin"), value: "LastLogin" },
    { label: t("reportcolumns.dialoguserregister.datejoin"), value: "DateJoin" },
  ];

  const totalRewardColumn = [
    { label: t("reportcolumns.dialogtotalreward.rewardtype"), value: "RewardType" },
    { label: t("reportcolumns.dialogtotalreward.rewardamount"), value: "RewardAmount" },
    { label: t("reportcolumns.dialogtotalreward.rewardtime"), value: "RewardTime" },

  ];

  const calculatedCommissionColumn = [
    { label: t("reportcolumns.dialogcalculatedcommission.provider"), value: "Provider" },
    { label: t("reportcolumns.dialogcalculatedcommission.companywinloss"), value: "CompanyWinLoss" },
    { label: t("reportcolumns.dialogcalculatedcommission.bonus"), value: "Bonus" },
    { label: t("reportcolumns.dialogcalculatedcommission.actualrebate"), value: "ActualRebate" },
    { label: t("reportcolumns.dialogcalculatedcommission.commissionrate"), value: "CommissionRate" },
    { label: t("reportcolumns.dialogcalculatedcommission.calculatedcommission"), value: "CalculatedCommission" },
    { label: t("reportcolumns.dialogcalculatedcommission.othercost"), value: "OtherCost" },

  ];


  let sumRow = {}
  let columns = [];
  let dataSource = [];

  const playerProfileId = selectedData?.Id || selectedData?.PlayerProfileId;
  const startdate = startDate;
  const enddate = endDate;


 // console.log(playerProfileId);
  const {
    data: depositHistory,
    isSuccess: isGetDownlineDepositHistorySuccess,
  } = useGetDownlineDepositHistoryQuery({ playerProfileId }, { skip: !playerProfileId });

  const {
    data: betHistory,
    isSuccess: isGetDownlineBetHistorySuccess,
  } = useGetDownlineBetHistoryQuery({ playerProfileId ,startdate,enddate}, { skip: !playerProfileId || !startdate || !enddate, });

  const {
    data: individualSummary,
    isSuccess: isGetDownlineIndividualSummarySuccess,
  } = useGetDownlineIndividualSummaryQuery({ startdate,enddate}, { skip: !startdate || !enddate, });

  const {
    data: expensesDetail,
    isSuccess: isGetAffiliateExpensesDetailSuccess,
  } = useGetAffiliateExpensesDetailQuery({ startdate,enddate}, { skip: !startdate || !enddate, });

  const {
    data: bettingsValidBet,
    isSuccess: isGetBettingsValidBetSuccess,
  } = useGetAffiliateDownlineBetProviderQuery({ startdate,enddate}, { skip: !startdate || !enddate, });

  const {
    data: depositTotalCount,
    isSuccess: isGetDepositTotalCountSuccess,
  } = useGetDepositTotalCountReportQuery({ startdate,enddate}, { skip: !startdate || !enddate, });

  const {
    data: withdrawalTotalCount,
    isSuccess: isGetWithdrawalTotalCountSuccess,
  } = useGetWithdrawalTotalCountReportQuery({ startdate,enddate}, { skip: !startdate || !enddate, });

  const {
    data: firstDeposits,
    isSuccess: isGetFirstDepositDetailsSuccess,
  } = useGetDownlineFirstDepositDetailsQuery({ startdate,enddate}, { skip: !startdate || !enddate, });

  const {
    data: registerData,
    isSuccess: isGetAffiliateDownlineRegisterDataSuccess,
  } = useGetAffiliateDownlineRegisterDataQuery({ startdate,enddate}, { skip: !startdate || !enddate, });

  const {
    data: bonusDetail,
    isSuccess: isGetAffiliateDownlineBonusDetailSuccess,
  } = useGetAffiliateDownlineBonusDetailQuery({ startdate,enddate}, { skip: !startdate || !enddate, });

  const {
    data: commissionDetail,
    isSuccess: isGetAffiliateCommissionDetailSuccess,
  } = useGetAffiliateCommissionDetailQuery({ startdate,enddate}, { skip: !startdate || !enddate, });


  switch (buttonClicked.trim()) {
    case 'MoreDetails':
      columns = betHistoryColumn;
  
      if (isGetDownlineBetHistorySuccess) {
        dataSource = betHistory?.data && Array.isArray(betHistory.data)
          ? betHistory.data.map(item => ({
              Member: item.username,
              Provider: item.providerName,
              Turnover: item.turnover,
              WinLoss: item.winLoss,
            }))
          : [];
      }
      break;
  
    case 'Action':
      columns = depositHistoryColumn;
  
      if (isGetDownlineDepositHistorySuccess) {
        dataSource = depositHistory?.data && Array.isArray(depositHistory.data)
          ? depositHistory.data.map(item => ({
              Id: item.depositId,
              BankName: item.bankName,
              Method: item.method,
              Amount: item.amount,
              DepositDate: moment(item.depositDate).format('YYYY/MM/DD HH:mm:ss'),
              ApprovedDate: item.approvedDate
                ? moment(item.approvedDate).format('YYYY/MM/DD HH:mm:ss')
                : 'N/A',
              Status: item.status,
            }))
          : [];
      }
      break;
  
    case 'TotalCompanyWinLoss':
      columns = memberSummaryColumn;
  
      if (isGetDownlineIndividualSummarySuccess) {
        dataSource = individualSummary?.data && Array.isArray(individualSummary.data)
          ? individualSummary.data.map(item => ({
              MemberId: item.memberId,
              Username: item.username,
              TotalDeposit: item.totalDeposit,
              TotalWithdrawal: item.totalWithdrawal,
              AdjustmentAmount: item.adjustmentAmount,
              TotalBetCount: item.totalBetCount,
              TotalValidBet: item.totalValidBet,
              PlayerWinLoss: item.playerWinLoss,
              TotalBonus: item.totalBonus,
              TotalRebate: item.totalRebate,
              TotalReward: item.totalReward,
              TotalWinLoss: item.totalWinLoss,
            }))
          : [];
      //  console.log(dataSource);
      }
      break;
  
    case 'Expenses':
      columns = expensesDetailColumn;
  
      if (isGetAffiliateExpensesDetailSuccess) {
        const expensesData = [
          expensesDetail.data.adjustmentAmount,
          expensesDetail.data.bonusAmount,
          expensesDetail.data.rebateAmount,
          expensesDetail.data.rewardAmount,
        ];
  
        dataSource = expensesDetailRow.map((item, index) => ({
          Item: item,
          TotalValue: expensesData[index] ?? 0, // Default to 0 if value is null or undefined
        }));
     //   console.log(dataSource);
      }
      break;
  
    case 'Betting Valid Bet':
      columns = bettingsValidBetColumn;
  
      if (isGetBettingsValidBetSuccess) {
        dataSource = bettingsValidBet?.data && Array.isArray(bettingsValidBet.data)
          ? bettingsValidBet.data.map(item => ({
              Username: item.username,
              ProviderName: item.providerName,
              GameType: item.gameType,
              BetAmount: Math.abs(item.turnoverAmount),
              ValidBetAmount: Math.abs(item.turnoverAmount),
              WinLossAmount: item.winLossAmount,
              TotalValidBet: item.totalValidBet,
              Turnover: item.turnoverAmount,
              SettleTime: item.settleDate
                ? moment(item.settleDate).format('YYYY/MM/DD HH:mm:ss')
                : 'N/A',
            }))
          : [];
       // console.log(dataSource);
      }
      break;
  
    case 'Deposit Total Count':
      columns = depositTotalColumn;
  
      if (isGetDepositTotalCountSuccess) {
        dataSource = depositTotalCount?.data && Array.isArray(depositTotalCount.data)
          ? depositTotalCount.data.map(item => ({
              Username: item.username,
              DepositId: item.depositId,
              DepositMethod: item.method,
              BankName: item.bankName,
              ReceivedAmount: item.depositAmount,
              DepositTime: item.dateCreated
                ? moment(item.dateCreated).format('YYYY/MM/DD HH:mm:ss')
                : 'N/A',
              ApprovedTime: item.approvedTime
                ? moment(item.approvedTime).format('YYYY/MM/DD HH:mm:ss')
                : 'N/A',
            }))
          : [];
      //  console.log(dataSource);
      }
      break;
  
    case 'Withdrawal Total Count':
      columns = withdrawalTotalColumn;
  
      if (isGetWithdrawalTotalCountSuccess) {
        dataSource = withdrawalTotalCount?.data && Array.isArray(withdrawalTotalCount.data)
          ? withdrawalTotalCount.data.map(item => ({
              Username: item.username,
              WithdrawId: item.withdrawalId,
              WithdrawType: item.method,
              BankName: item.bankName,
              WithdrawAmount: item.withdrawalAmount,
              WithdrawTime: item.dateCreated
                ? moment(item.dateCreated).format('YYYY/MM/DD HH:mm:ss')
                : 'N/A',
              ApprovedTime: item.approvedTime
                ? moment(item.approvedTime).format('YYYY/MM/DD HH:mm:ss')
                : 'N/A',
            }))
          : [];
       // console.log(dataSource);
      }
      break;
  
    case 'User First Deposits':
      columns = firstTimeDepositColumn;
  
      if (isGetFirstDepositDetailsSuccess) {
        dataSource = firstDeposits?.data && Array.isArray(firstDeposits.data)
          ? firstDeposits.data.map(item => ({
              Username: item.username,
              FirstTimeDepositDate: moment(item.firstDepositDate).format('YYYY/MM/DD HH:mm:ss'),
              Currency: process.env.REACT_APP_CURRENCY_CODE,
              Amount: item.firstDepositAmount,
            })).filter(item => item.Amount !== null)
          : [];
       // console.log(dataSource);
      }
      break;
  
    case 'User Registers':
      columns = userRegisterColumn;
  
      if (isGetAffiliateDownlineRegisterDataSuccess) {
        dataSource = registerData?.data && Array.isArray(registerData.data)
          ? registerData.data.map(item => ({
              MemberId: item.code,
              Username: item.username,
              Email: item.email,
              MobileNo: item.mobileNo,
              LastLogin: item.lastLoginDate
                ? moment(item.lastLoginDate).format('YYYY/MM/DD HH:mm:ss')
                : 'N/A',
              DateJoin: item.registerDate
                ? moment(item.registerDate).format('YYYY/MM/DD HH:mm:ss')
                : 'N/A',
            }))
          : [];
       // console.log(dataSource);
      }
      break;
  
    case 'Total Reward':
      columns = totalRewardColumn;
  
      if (isGetAffiliateDownlineBonusDetailSuccess) {
        dataSource = bonusDetail?.data && Array.isArray(bonusDetail.data)
          ? bonusDetail.data.map(item => ({
              RewardType: item.rewardType,
              RewardTime: moment(item.rewardTime).format('YYYY/MM/DD HH:mm:ss'),
              RewardAmount: item.rewardAmount,
            })).filter(item => item.rewardAmount !== null)
          : [];
       // console.log(dataSource);
      }
      break;

      case 'CalculatedCommission':
        columns = calculatedCommissionColumn;
    
        if (isGetAffiliateCommissionDetailSuccess) {
          dataSource = commissionDetail?.data && Array.isArray(commissionDetail.data)
            ? commissionDetail.data.map(item => ({
              Provider: item.provider,
              CompanyWinLoss: item.companyWinLoss ?? 0,
              Bonus: item.bonus ?? 0,
              ActualRebate: item.actualRebate ?? 0,
              CommissionRate: item.commissionRate ?? 0,
              CalculatedCommission: item.calculatedCommission ?? 0,
              OtherCost: item.otherCost ?? 0,
              }))
            : [];
         // console.log(dataSource);
        }
        break;
  
    default:
     // console.log(`Default case reached. Value: "${buttonClicked}"`);
  }
  

   
    




  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 8));
    setPage(0);
  };

  useEffect(() => {
    if (open) {
      setPage(0);  
    }
  }, [open]);


  const paginatedData = dataSource.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


  return (
    <div>
      {/* Dialog component for pop-up */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle style={{ fontSize: '20px', color: 'white', fontWeight: 'bold', padding: '20px 30px', backgroundColor: '#313338' }}>
          Detailed Report
        </DialogTitle>
        <DialogContent style={{ padding: '20px', backgroundColor: '#313338' }}>
          <TableContainer
            component={Paper}
            style={{
              border: '1px solid #ccc',
              borderRadius: '10px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '12px', backgroundColor: '#d3d3d3' }}>
                        Detail
                      </TableCell> */}
                  {columns.map((column, index) => (
                    <TableCell key={index} style={{ textWrap: "nowrap", backgroundColor: '#d3d3d3', fontWeight: 'bold' }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody style={{ backgroundColor: 'white' }}>
                {paginatedData.length > 0 ? (
                  paginatedData.map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {columns.map((column, colIndex) => (
                        <TableCell key={colIndex} sx={{ textWrap: "nowrap", color: "black", backgroundColor: '#f5f5f5' }}>
                          {clickableColumns.includes(column.value) ? (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleRowClick(column.value, row)}
                            >
                              {row[column.value]}
                            </Button>
                          ) : (
                            row[column.value]
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length + 1} align="center">
                    {t("others.nodata")}
                    </TableCell>
                  </TableRow>
                )}
                {/* {selectedData &&
                      selectedData.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ padding: '12px', fontSize: '14px', borderBottom: '1px solid #e0e0e0' }}>
                            {row.Detail}
                          </TableCell>
                          <TableCell style={{ padding: '12px', fontSize: '14px', borderBottom: '1px solid #e0e0e0' }}>
                            {row.Info}
                          </TableCell>
                        </TableRow>
                      ))} */}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        {/* Pagination Component */}
        <CustomPagination
          component="div"
          count={dataSource.length} 
          page={page} 
          onPageChange={handleChangePage} 
          rowsPerPage={rowsPerPage} 
          onRowsPerPageChange={handleChangeRowsPerPage} 
          backgroundColor="transparent"
          sx={{
            display: 'none',
            '&.MuiPaginationItem-root': {
              backgroundColor: 'white !important',
            }          
          }}
          
        />
        <DialogActions style={{ padding: '20px', backgroundColor: '#313338' }}>
          <Button
            onClick={handleClose}
            color="primary"
            variant="contained"
            style={{
              backgroundColor: '#f39c12',
              color: '#fff',
              padding: '10px 20px',
              fontSize: '14px',
              fontWeight: 'bold',
              borderRadius: '8px'
            }}
          >
            Close
          </Button>
        </DialogActions>

      </Dialog>
    </div >
  );
};


export default AffiliateReportPopUp;